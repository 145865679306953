@import 'src/assets/styles/mixins';

.property {
	position: relative;
	padding: 8px 16px;

	&_name {
		color: $color-text-name;
		font-weight: 700;
		font-size: 12px;
	}

	&_value {
		color: $color-black;
		font-weight: 500;
		font-size: 16px;
	}

	&_edit {
		position: absolute;
		top: 18px;
		right: 16px;
	}

	&_editable {
		background-color: $color-white;
	}
}

.property:not(:last-child) {
	border-bottom: 2px solid $color-border-gray;
}
