@import 'src/assets/styles/mixins';

.header {
	@include respond-above(md) {
		&_small {
			display: none;
		}
	}

	@include respond-below(md) {
		&_big {
			display: none;
		}
	}
}

.empty {
	margin: 0 50px;
	padding: 12px 0;
	color: $color-gray-dark;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	border-top: 1px solid $color-gray-lightest;

	@include respond-below(md) {
		margin: 0 16px;
	}
}

.profile {
	min-height: 120vh;
}
