@import 'src/assets/styles/mixins';

$width: 135px;

.month {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: $width;
	background-color: $color-white;
	border-radius: 3px;
	cursor: pointer;

	&_text {
		padding: 4px 8px;
		color: $color-base-grey;
		font-size: 12px;
	}

	&_arrow {
		padding: 4px 8px;
		border-left: 1px solid $color-service-grey;
	}
}

.panel {
	position: relative;

	&_list {
		position: absolute;
		z-index: 1;
		width: $width;
		margin-top: 2px;
	}
}
