@import 'src/assets/styles/mixins';

.filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
}

.active::after {
	position: absolute;
	top: 44%;
	left: 4px;
	display: block;
	width: 5px;
	height: 5px;
	background: $color-green;
	border-radius: 50%;
	content: '';
}

.item {
	width: 100%;
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 12px;
	text-align: left;

	&_label {
		display: block;
		margin-bottom: 8px;
		color: $color-base-grey;
	}

	&_line {
		display: flex;
		justify-content: space-between;

		[class*='ant-picker-focused'] {
			background: $color-white;
			border: 1px solid $color-green;
			box-shadow: none;
		}

		[class*='ant-picker']:not([class*='ant-picker-input'], [class*='ant-picker-focused']):hover {
			border: 1px solid $color-service-grey;
		}

		&__item {
			width: 48%;
			height: 36px;
			background: $color-service-grey;
			border: 1px solid $color-service-grey;
			border-radius: 3px;

			[class*='ant-picker-input'] input {
				padding-top: 2px;
				font-size: 12px;
			}

			&:first-child::before,
			&:last-child::before {
				display: block;
				margin-right: 8px;
				color: #c3cad3;
				font-size: 12px;
			}

			&:first-child::before {
				content: attr(title);
			}

			&:last-child::before {
				content: attr(title);
			}
		}
	}

	&:hover {
		color: $color-gray-dark;
	}
}

@include respond-below(xs) {
	.filter {
		padding: 0;
	}

	.item {
		width: 100%;
	}
}
