@import 'src/assets/styles/mixins';

.action {
	display: flex;
	padding: 15px;
	cursor: pointer;
	transition: background-color 1s;

	&_active {
		background-color: $color-bread;
	}

	&_disabled {
		svg > path {
			fill: $color-bread;
		}
		cursor: default;
	}

	&_hided {
		cursor: default;
		opacity: 0;
		transition: opacity 0.5s;

		@include respond-below(xs) {
			display: none;
		}
	}

	&:hover {
		background-color: $color-bread;
	}
}

.text {
	margin-left: 10px;
	color: $color-white;
	font-size: 12px;

	&_disabled {
		color: $color-bread;
	}

	&_disabled:hover {
		color: $color-bread;
	}

	&_mobile {
		color: $color-orange;
	}
}
