@import 'assets/styles/mixins';

.current {
	display: flex;
	color: $color-base-grey;
	font-size: 18px;
	opacity: 1;
	transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;

	&_month {
		margin-right: 5px;
	}
}
