@import 'src/assets/styles/mixins';

.block {
	flex-grow: 1;
	color: $color-gray-dark;
	font-size: 16px;

	&__label {
		display: block;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	&__input {
		width: 100%;
		margin-bottom: 16px;
		padding: 12px;
		border: 1px solid $color-gray-light;
		border-radius: 5px;
	}

	&__required,
	&__error {
		color: $color-red;
	}

	&__error {
		margin: 2px 12px 10px;
		font-size: 12px;
	}

	@include respond-below(xs) {
		font-size: 12px;
	}
}

.divide {
	flex-grow: 1;
	width: 25%;

	input {
		text-align: center;
	}
}
