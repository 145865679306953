@import 'src/assets/styles/mixins';

.desktop {
	display: block;

	@include respond-below(xs) {
		display: none;
	}
}

.mobile {
	display: block;
	margin-bottom: 80px;

	@include respond-above(xs) {
		display: none;
	}
}

.ordersInfo {
	display: flex;
	width: calc(100% - 32px);
	margin: 0 16px;
	border: 1px solid $color-gray-light;
	border-radius: 5px;
}

.properties {
	flex-basis: 300px;

	&_list {
		margin: 0;
		padding: 0;
	}

	&_item {
		padding: 6px 20px;
		color: $color-black-dark;
		font-weight: 500;
		font-size: 12px;
		line-height: 26px;
		border-left: 1px solid $color-gray-light;

		[class*='loader'] {
			margin: 5px auto;
		}
	}

	&_item:nth-of-type(2n) {
		background: $color-white;
	}
}

.properties_item.isLeft {
	[class*='loader'] {
		margin-left: 10px;
	}
}

.orders {
	display: flex;
	width: 100%;
	overflow-x: auto;
}

.order {
	flex-grow: 1;

	&_id {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $color-green-dark;
	}

	&_close {
		display: inline-block;
		cursor: pointer;

		&:hover svg > path {
			fill: $color-gray-text;
		}
	}
}
