@import 'src/assets/styles/mixins';

.nav,
.subnav {
	background-color: inherit;
}

.list {
	display: flex;
	height: 100%;
	margin: 0;
	padding-left: 0;

	&_link {
		display: inline-flex;
		align-items: start;
		height: 100%;
		margin: 0 20px;
		color: $color-black;
		font-weight: 600;
		cursor: pointer;

		&__active {
			position: relative;
			z-index: 100;
			color: $color-green-dark;
			border-bottom: 1px solid $color-green-dark;

			@include respond-below(xs) {
				color: $color-green;
			}
		}

		&__submenu {
			color: $color-green-dark;
			border-bottom: none;
		}

		&__scroll {
			align-items: center;
			color: $color-text-name;

			@include respond-below(xl) {
				margin: 0 8px;
				font-size: 14px;

				&:first-child {
					margin-left: 4px;
				}
			}
		}

		&__open {
			color: $color-green-dark;
		}

		&:hover {
			color: $color-gray-dark;
		}
	}
}

.nav {
	display: flex;
	justify-content: space-between;
	height: 38px;
}

.subnav {
	margin-top: 32px;

	[class*='list'] {
		display: flex;
		flex-direction: column;
	}

	[class*='link'] {
		margin: 0 16px 16px;

		@include respond-below(xs) {
			font-size: 18px;
		}
	}
}

@include respond-below(lg) {
	.nav {
		display: none;
	}
}

@include respond-above(lg) {
	.subnav {
		display: none;
	}
}
