@import 'src/assets/styles/mixins';

.container {
	flex: 1 1 0;
	width: 100%;
	cursor: pointer;
}

.dropdown {
	box-sizing: border-box;
	border-radius: 8px;

	&__active div {
		color: $color-white;
		border-radius: 8px;

		svg > path {
			fill: $color-white;
		}
	}

	&__active:hover {
		[class*='text'] {
			color: $color-white;
		}

		svg > path {
			fill: $color-white;
		}
	}
}

.listContainer {
	position: relative;
	visibility: hidden;
	opacity: 0;

	&__opened {
		visibility: visible;
		opacity: 1;
	}
}

.list {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1500;
	width: 100%;
	height: 100%;
}

@include respond-above(xs) {
	.dropdown {
		&__active [class*='events'] {
			&:hover {
				svg > path {
					fill: $color-white;
				}
			}
		}
	}
}

@include respond-below(xs) {
	.dropdown {
		&__active div,
		&__active:hover {
			svg > path {
				fill: $color-green;
			}
		}
	}

	.listContainer {
		display: none;
	}

	.events {
		padding: 0;
	}
}
