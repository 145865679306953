@import 'src/assets/styles/global';

.box {
	&__left {
		@extend %flex;
		flex-basis: max-content;

		&_text {
			padding: 0 7px;
		}
	}
}
