@import 'src/assets/styles/mixins';

.load {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: opacity-animation;
}

@keyframes opacity-animation {
	0% {
		opacity: 0;
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.inner {
	position: absolute;
	display: inline-block;
	width: 42%;
	height: 42%;
	margin: 0;
	border: 0;
	border-radius: 8px;
	animation: inner-animation 2s ease infinite;

	&:first-child {
		background: #909f90;
		animation-delay: -1.5s;
	}

	&:nth-child(2) {
		background: $color-gray;
		animation-delay: -1s;
	}

	&:nth-child(3) {
		background: #a0c0a0;
		animation-delay: -0.5s;
	}

	&:last-child {
		background: #7c7b7b;
	}
}

@keyframes inner-animation {
	0%,
	100% {
		transform: translate(0);
	}

	25% {
		transform: translate(160%);
	}

	50% {
		transform: translate(160%, 160%);
	}

	75% {
		transform: translate(0, 160%);
	}
}
