@import 'assets/styles/mixins';

.banner {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 270px;
	overflow-y: hidden;
	background-color: $color-primary-dark-opacity;
	border-radius: 16px;

	&_main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 70%;
		padding: 24px;
	}

	&_info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	&_title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: $color-brand-secondary;
		font-weight: 700;
		font-size: 20px;
		text-transform: uppercase;
	}

	&_logos {
		align-self: center;
		margin-top: 2px;
		margin-left: 16px;
	}

	&_description {
		display: flex;
		flex-grow: 1;
		align-items: center;
		color: $color-brand-secondary;
		font-weight: 300;
		font-size: 18px;
		line-height: 22px;
		text-align: left;
	}

	&_actions {
		display: flex;
		justify-content: space-between;
	}

	&_action {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 49%;
		padding: 16px;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		border-radius: 8px;
		cursor: pointer;
		transition: background-color 0.5s, color 0.5s linear;

		&__arrow {
			display: flex;
			align-items: center;

			svg path {
				transition: stroke 0.5s linear;
			}
		}
	}

	&_more {
		color: $color-white;
		background-color: $color-green;
		border: 1px solid $color-green;

		&:hover {
			background-color: $color-green-dark;
		}

		svg path {
			stroke: $color-white;
		}
	}

	&_consultation {
		color: $color-typography-tertiary;
		border: 1px solid $color-gray-light;

		&:hover {
			color: $color-white;
			background-color: $color-green;
			border: 1px solid $color-green;

			svg path {
				stroke: $color-white;
			}
		}
	}

	&_images {
		&_row {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			width: 100%;
			height: 50%;

			img {
				width: 50%;
				height: auto;
			}
		}
	}
}

@include respond-below(sm) {
	.banner {
		flex-direction: column;
		height: auto;

		&_main {
			width: 100%;
			padding: 16px;
		}

		&_logos {
			margin: 0;
		}

		&_title {
			flex-direction: column;
		}

		&_description {
			margin-top: 20px;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
		}

		&_actions {
			display: block;
			margin-top: 16px;
			margin-bottom: 16px;
		}

		&_action {
			width: 100%;
		}

		&_more {
			margin-bottom: 8px;
		}
	}
}
