@import 'assets/styles/mixins';

.public {
	margin-bottom: 40px;
	padding: 16px 20px;
	line-height: 16px;

	&_schedule {
		margin: 50px 0;
	}

	&_title {
		color: $color-typography-secondary;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
	}

	&_text {
		color: $color-typography-tertiary;
		font-weight: 400;
		font-size: 10px;
	}
}
