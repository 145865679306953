@import 'src/assets/styles/mixins';

.icons {
	display: flex;
	justify-content: space-between;
}

.item {
	display: flex;
	align-self: center;
	margin-left: 20px;
	cursor: pointer;
}

.submenu {
	display: flex;
	justify-content: space-between;
	padding: 16px;

	[class*='item'] {
		margin-left: 0;
	}

	&_left {
		display: flex;
		flex-basis: 160px;
		align-items: center;
		justify-content: space-between;
	}

	&__list {
		background-color: $color-white;
	}
}

.tutorial {
	width: 100%;
	padding: 0 20px 20px;
}

.active > svg > path,
.active [class*='events'] > svg > path {
	fill: $color-green;
}

@include respond-below(xs) {
	.item:first-child {
		margin-left: 0;
	}
}

@media (max-width: 300px) {
	.item:last-child {
		margin-left: 10px;
	}

	.item img,
	svg {
		max-height: 24px;
	}
}

@include respond-above(md) {
	.icons {
		display: none;
	}
}
