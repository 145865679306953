@import 'src/assets/styles/mixins';

.hint {
	position: relative;

	&__sign {
		display: flex;
		align-self: center;

		&:hover {
			cursor: pointer;

			svg > path {
				fill: $color-green-dark;
			}
		}
	}

	&__arrow {
		position: absolute;
		top: 18px;
		right: 0;
		border: 6px solid transparent;
		border-bottom: 6px solid #000000bd;
		visibility: hidden;
		opacity: 0;
	}

	&__text {
		position: absolute;
		top: 30px;
		right: -20px;
		width: 380px;
		height: 70px;
		padding: 10px 12px;
		color: $color-white;
		font-weight: 400;
		font-size: 10px;
		text-align: left;
		background: #000000bd;
		border-radius: 12px;
		box-shadow: 0 15px 30px 0 #0000001a;
		visibility: hidden;
		opacity: 0;
	}

	&__text.visible,
	&__arrow.visible {
		visibility: visible;
		opacity: 1;
	}
}
