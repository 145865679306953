@import 'src/assets/styles/mixins';

.active {
	padding: 30px 16px 0;
	color: $color-gray-text;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
}

@include respond-below(xs) {
	.active {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 0 0;
	}
}
