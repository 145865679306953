@import 'src/assets/styles/mixins';

.submenu {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1100;
	width: 260px;
	min-height: 110vh;
	background-color: $color-white;
	box-shadow: $shadow-menu;
	transform: translateX(0);
	transition: transform 0.4s ease-in-out;

	&__closed {
		transform: translateX(300px);
	}
}

@include respond-below(xs) {
	.submenu {
		bottom: 0;
		width: 100%;
		overflow-y: auto;

		&__closed {
			transform: translateX(calc(100%));
		}
	}
}
