@import 'assets/styles/mixins';

.actions {
	width: 60%;
	padding: 20px 20px 0;
	color: $color-cookies;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	text-align: justify;

	&_block {
		display: flex;
		padding-bottom: 26px;
	}

	&_text {
		margin-right: 10px;

		&__disable {
			color: $color-files-result;
		}
	}

	&_check {
		margin-left: -8px;
		border: 1px solid #e5e5e5;
	}

	&_save {
		max-width: 300px;
		padding: 12px 18px;
		color: $color-white;
		background: $color-green;
		border-radius: 6px;
		cursor: pointer;
		transition: background 0.3s ease-in-out;

		&:hover {
			background: $color-green-dark;
		}
	}
}

@include respond-below(md) {
	.actions {
		width: 100%;
		padding: 20px 0 0;
	}
}
