@import 'src/assets/styles/mixins';

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 20px 8px;
}

.tutorial {
	width: 100%;
	padding: 8px;
	color: $color-gray-text;
	background: $color-service-grey;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background: $color-gray-light;
	}

	&__top {
		display: flex;
		align-items: start;
		justify-content: space-between;
		font-weight: 500;
		font-size: 16px;
	}

	&__bottom {
		margin-top: 8px;
		color: $color-gray-dark;
		font-size: 12px;
	}
}
