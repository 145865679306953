@import 'src/assets/styles/mixins';

.year {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 24px 20px;
	transition: opacity 0.3s ease-in-out;
}

.row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}
