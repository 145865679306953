@import 'assets/styles/mixins';

.month {
	transition: opacity 0.3s ease-in-out;

	&_week {
		display: flex;
		border-bottom: 1px solid #f0f3f6;
	}

	&_day {
		flex: 1;
		padding: 8px;
		color: #373c464d;
		font-size: 14px;
		text-align: right;
		border-right: 1px solid #f0f3f6;
	}
}
