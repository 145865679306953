@import 'src/assets/styles/mixins';

.dropButton {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: max-content;
	height: 36px;
	padding: 10px;
	cursor: pointer;
}

.icon {
	display: flex;
	align-self: center;
}

.text {
	margin-left: 5px;
	font-weight: 600;
	font-size: 11px;
	line-height: 16px;
}

@include respond-below(xs) {
	.dropButton {
		padding: 0;
	}

	.text {
		margin-left: 0;
	}
}
