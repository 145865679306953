@import 'src/assets/styles/mixins';

.scrollbar {
	min-height: calc(100vh + 20%);
	overflow-x: auto;
	scrollbar-width: thin;
	scrollbar-color: $scroll-color $scroll-field-color;

	&::-webkit-scrollbar {
		height: 20px;
		background: $scroll-field-color;
	}

	&::-webkit-scrollbar:double-button {
		color: $scroll-color;
	}

	&::-webkit-scrollbar-track {
		background: $scroll-field-color;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scroll-color;
		border: 6px solid $scroll-field-color;
		border-radius: 50px;
	}
}

@include respond-below(xs) {
	.scrollbar {
		padding-bottom: 0;
		overflow-x: unset;
	}
}
