@import 'assets/styles/global';

.button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 175px;
	height: 36px;
	padding: 10px 16px;
	border: 1px solid $color-service-grey;
	border-radius: 8px;
	transition: border 0.3s ease, box-shadow 0.3s ease;

	&:hover {
		border-color: $color-green;
		box-shadow: $shadow-button;
	}

	&_text {
		font-weight: 600;
		font-size: 11px;
	}

	&_opened {
		width: inherit;
		border: 1px solid $color-green;
	}

	&_arrow {
		display: flex;
		align-self: center;

		&__isClose {
			transform: rotate(180deg);

			svg > path {
				fill: $color-white;
			}

			&:hover {
				svg > path {
					fill: $color-white;
				}
			}
		}
	}
}
