@import 'src/assets/styles/mixins';

.settingList {
	position: relative;
	z-index: 2;
	width: 260px;
	margin-top: 10px;
	background: $color-white;
	border-radius: 5px;
	box-shadow: $shadow;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
	line-height: 16px;
	border-bottom: 1px solid #e4e4ef;

	&__title {
		font-weight: bold;
		font-size: 12px;
	}
}

.body {
	padding: 20px;
	border-bottom: 1px solid #e4e4ef;
}

.item {
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 12px;
	text-align: left;

	&:hover {
		color: $color-gray-dark;
	}
}

.passive {
	color: $color-gray-dark;
}

.action {
	padding: 20px;
	color: $color-green;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	cursor: pointer;

	&:hover {
		color: $color-green-dark;
	}
}

@include respond-below(xs) {
	.settingList {
		width: 100%;
		padding-bottom: 20px;
		background-color: $color-grayer;
		box-shadow: none;
	}

	.header {
		padding-right: 0;
		padding-left: 0;
		font-size: 14px;
	}

	.body {
		margin-right: -16px;
		margin-left: -16px;
		padding: 16px;
		background-color: $color-grayer;
		border-bottom: none;
	}

	.action {
		position: fixed;
		bottom: 16px;
		width: 100%;
		margin: -16px;
		background-color: $color-gray-darkest;
	}

	.button {
		padding: 16px;

		&:first-child {
			text-align: left;
			border-right: none;
		}

		&:last-child {
			color: $color-gray-dark;
			text-align: right;
		}
	}
}
