@import 'src/assets/styles/mixins';

.quantity {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 44px;
	border: 1px dashed #8dc64040;
	cursor: pointer;

	&_edit {
		display: flex;
		align-items: center;
		width: 20px;
	}

	&_default {
		margin: 0;
		text-align: center;
	}
}

.edit {
	display: flex;
	background: $color-white;
	border: 1px solid $color-green;
}

.edit.error,
.quantity.error {
	border: 1px solid $color-redder;
}

.goodQuantity {
	color: $color-gray-darker;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	text-align: right;
	background: inherit;
	outline: none;

	@include respond-below(xs) {
		min-width: 57px;
	}
}

input.goodQuantity {
	margin-bottom: 0;
	padding: 11px;
	border: 0;
	border-radius: unset;
}

@include respond-below(xs) {
	.quantity {
		justify-content: end;
		min-width: 80px;
		min-height: 20px;
		border: none;

		&_value {
			margin: 0;
		}

		&_edit {
			display: none;
		}
	}

	.edit input {
		text-align: center;
	}
}
