@import 'src/assets/styles/mixins';

.notifications {
	margin-bottom: 16px;
	padding: 16px;
	background: $color-files-gray;
	border-radius: 5px;

	&_title {
		margin-bottom: 16px;
		color: $color-text-name;
		font-size: 12px;
	}

	&_actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&_text {
		margin: 0;
	}
}
