@import 'src/assets/styles/mixins';

.reportsList {
	width: max-content;
	background-color: $color-white;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	box-shadow: 0 8px 14px 0 #0000000f;
}

.list {
	&_link {
		display: block;
		padding: 8px;
		color: $color-base-grey;
		font-size: 12px;
		cursor: pointer;

		&:hover {
			background-color: $color-service-grey;
		}

		&:last-child {
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}
}
