@import 'src/assets/styles/mixins';

.select {
	position: relative;
	width: 100%;
	height: 36px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		padding: 6px 12px;
		color: $color-base-grey;
		background: $color-service-grey;
		border: 1px solid $color-service-grey;
		border-radius: 3px;
		cursor: pointer;

		svg > path {
			fill: $color-base-grey;
		}

		&_left {
			display: flex;
			flex: 1;
			align-items: center;

			@include respond-below(xs) {
				font-size: 16px;
			}
		}

		&_closed {
			&::before {
				display: inline-block;
				width: 5px;
				height: 5px;
				margin-right: 5px;
				background: $color-green;
				border-radius: 50%;
				content: '';
			}
		}

		&_opened {
			background: $color-white;
			border-top: 1px solid $color-green;
			border-right: 1px solid $color-green;
			border-bottom: none;
			border-left: 1px solid $color-green;
			border-bottom-right-radius: unset;
			border-bottom-left-radius: unset;

			svg > path {
				fill: $color-green;
			}
		}

		.rotate {
			transform: rotate(180deg);
		}
	}

	&__item {
		padding: 8px 12px;
		background: transparent;

		&:hover {
			background-color: $color-service-grey;
		}

		[class*='checkBox_block'] {
			padding-top: 0;
		}
	}

	&__input {
		flex: 1;
		height: 34px;
		color: $color-base-grey;
		font-size: 12px;
		background: inherit;
		border: none;
		outline: none;

		@include respond-below(xs) {
			font-size: 16px;
		}
	}

	&__body {
		position: absolute;
		z-index: 2;
		width: 100%;
		max-height: 290px;
		padding: 0;
		overflow-y: auto;
		background-color: $color-white;
		cursor: pointer;

		&_trash {
			margin-left: 5px;
			line-height: 16px;
		}

		&_opened {
			border-top: 1px solid $color-service-grey;
			border-right: 1px solid $color-green;
			border-bottom: 1px solid $color-green;
			border-left: 1px solid $color-green;
			border-bottom-right-radius: 3px;
			border-bottom-left-radius: 3px;
		}

		&::-webkit-scrollbar {
			width: 4px;
			background: $color-opacity-green;
		}

		&::-webkit-scrollbar:double-button {
			color: $color-light-green;
		}

		&::-webkit-scrollbar-track {
			background: $color-opacity-green;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $color-light-green;
			border-radius: 50px;
		}
	}

	&__clear {
		display: flex;
		align-items: center;
		justify-content: space-between;

		svg > path {
			fill: $color-middle-gray;
		}
	}

	&__clean {
		display: flex;
		align-items: center;
		height: 0.1px;
		padding: 0 12px;
		color: $color-base-grey;
		transform: translateY(-32px);
		cursor: pointer;
		opacity: 0;
		transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;

		&_visible {
			height: unset;
			padding: 8px 12px;
			transform: translateY(0);
			opacity: 1;
		}
	}

	&__result {
		display: -webkit-box;
		max-height: 24px;
		overflow-y: hidden;
		color: $color-base-grey;
		font-weight: 400;
		font-size: 10px;
		font-style: italic;
		line-height: 12px;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
