@import 'src/assets/styles/mixins';

.empty {
	margin: 20px 16px 30px;
	padding: 16px;
	color: $color-green-dark;
	background: $color-green-opacity;
	border: 1px solid $color-green-dark;
	border-radius: 6px;
}

@include respond-below(xs) {
	.empty {
		margin-right: 0;
		margin-left: 0;
	}
}
