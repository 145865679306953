@import 'src/assets/styles/mixins';

.list {
	display: flex;
	align-items: center;
	padding: 0 8px;
	border: 1px solid $color-service-grey;
	border-radius: 20px;

	&__pages {
		width: 100%;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 8px;
			background-color: $color-white;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $color-gray;
			border-radius: 0;
		}

		&::-webkit-scrollbar-track {
			background-color: $color-white;
			border-radius: 0;
		}
	}

	&__search {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 5px 10px 5px 5px;
		padding-left: 5px;
		font-size: 12px;
		background: $color-white;
		border: 1px solid $color-gray;
		border-radius: 2px;

		&_empty {
			margin: 5px;
		}

		&_icon {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-right: 4px;
			padding: 8px 0;
		}

		&_focus {
			border: 1px solid $color-green;
		}
	}

	&__hidden {
		position: absolute;
		top: -205px;
		left: -25px;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 85px;
		overflow-y: auto;
		background: $color-white;
		border-radius: 2px;
		box-shadow: 0 0 8px 0 #0c10181f;

		&_less5 {
			top: -175px;
		}

		&_less4 {
			top: -145px;
		}

		&_less3 {
			top: -110px;
		}

		&_empty {
			top: -78px;
		}

		&_search {
			display: block;
			width: 90%;
			height: 100%;
			color: $color-typography-tertiary;
			font-size: 12px;
			border: 0;
			cursor: pointer;

			&_empty {
				padding-left: 5px;
			}
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		color: $color-typography-secondary;
		font-size: 14px;
		line-height: 16px;
		border-radius: 50%;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			color: $color-primary-dark;
			background: $color-service-grey;
		}

		&_center {
			width: 100%;
			margin-left: 5px;
			padding: 8px 5px;
			text-align: center;
			border-radius: unset;

			&:hover {
				color: $color-typography-primary;
				background: $color-opacity-green;
			}
		}

		&_active {
			color: $color-white;
			background: $color-green;

			&:hover {
				color: $color-white;
				background: $color-green;
			}
		}
	}

	&__block,
	&__center {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__center {
		position: relative;
	}

	&__dpropdown {
		display: block;
		width: 90%;
	}

	&__active {
		color: $color-white;
		background: $color-green;
		border-radius: 50%;
	}
}
