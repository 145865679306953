@import 'assets/styles/mixins';

.homepage {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	min-height: 120vh;
	padding: 20px;
}

.userHome {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	&__right {
		display: flex;
		flex-flow: column wrap;
		width: 65%;
	}

	&__banners {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 16px;
	}
}

@include respond-below(lg) {
	.homepage {
		margin: 0 auto;
		padding: 32px 20px;
	}

	.userHome {
		&__right {
			width: 100%;
		}
	}
}

@include respond-below(xs) {
	.homepage {
		padding: 32px 16px;
	}
}
