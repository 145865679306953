@import 'src/assets/styles/mixins';

.main {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

h1 {
	padding: 50px 0 32px 50px;

	@include respond-below(md) {
		padding: 32px 0 16px 16px;
		font-weight: bold;
	}
}
