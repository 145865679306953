@import 'assets/styles/mixins';

.doc {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 27px 20px;
	background-color: $color-white;
	border: 1px solid $color-service-grey;
	border-radius: 16px;
	cursor: pointer;
	transition: filter 0.5s linear;

	&:hover {
		box-shadow: $shadow;
		filter: brightness(95%);
	}

	&:hover &__image {
		background-color: $color-opacity-green;
		border: 1px solid $color-light-green;
	}

	&__content {
		width: 195px;
	}

	&__title {
		color: $color-dark-green;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
	}

	&__text {
		margin-bottom: 0;
		color: $color-typography-tertiary;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}

	&__image {
		display: flex;
		justify-content: center;
		padding: 17px;
		background-color: $color-service-grey;
		border: 1px solid $color-service-grey;
		border-radius: 50%;
		transition: border-color 0.5s ease-in-out, background-color 0.5s ease-in-out;
	}
}

@include respond-below(xs) {
	.doc {
		&__content {
			flex: 1;
		}
	}
}

@media (max-width: 360px) {
	.doc {
		justify-self: center;
		max-width: 295px;
		padding: 24px 16px;
	}
}
