@import 'src/assets/styles/mixins';

* {
	box-sizing: border-box;
}

html {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	scrollbar-width: thin;
}

body {
	width: 100%;
	margin: 0;
	padding: 0;
	color: $color-black;
	font-weight: 400;
	font-size: 14px;
	font-family: Roboto, sans-serif;
	line-height: 1.4em;
	background-color: $color-grayer;
	scroll-behavior: smooth;
}

h1 {
	margin: 0;
}

a {
	all: unset;
}

li {
	list-style-type: none;
}

[class*='ant-picker-dropdown'] {
	z-index: 10500;
}

[class='ant-picker-clear'] {
	display: none;
}

[class*='ant-picker-today'] {
	display: none;
}

input[type='text'] {
	outline: 0;
	appearance: none;
}

%flex {
	display: flex;
	align-items: center;
	justify-content: start;
}
