@import 'src/assets/styles/mixins';

.card {
	display: block;
	margin: 0 -16px;

	&_property {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 10px 16px;
		font-weight: 700;
		font-size: 12px;
		line-height: 20px;
		background: $color-white;
		border-top: 1px solid $color-gray-light;

		&.hide {
			display: none;
		}

		&_text {
			color: $color-text-name;
			font-size: 10px;
		}
	}

	&_id {
		margin-top: 6px;
		padding: 20px 16px;
		color: $color-black-dark;
		border: none;
	}

	&_margin {
		margin-bottom: 8px;
	}

	&_number {
		display: inline-block;
		margin-left: 5px;
		color: $color-green;
	}

	&_name {
		display: flex;
		align-items: center;
		justify-content: start;
	}

	&_arrow svg > path {
		fill: $color-gray-lightest;
	}

	&_visible {
		transform: rotate(180deg);

		svg > path {
			fill: $color-green;
		}
	}
}

.goodsTitle {
	padding: 20px 16px;
	color: $color-black;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	background: $color-white;

	.goodsHeader {
		display: inline-block;
		margin-right: 10px;
	}

	&_visible {
		svg {
			transform: rotate(180deg);
		}
	}
}
