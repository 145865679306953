@import 'src/assets/styles/mixins';

.message {
	margin-left: -10px;
	color: $color-white;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
}

.title {
	color: $color-white;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
}

.links {
	display: flex;
	justify-content: start;

	a {
		margin-right: 5px;
		margin-left: 5px;
		color: $color-green;
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			color: $color-gray-light;
		}
	}
}

[class='ant-modal-content'] {
	padding: 16px !important;
	background: #000000bd !important;
	border-radius: 6px !important;
	box-shadow: 0 15px 30px 0 #0000001a !important;
}

[class*='ant-modal-close'] {
	top: 14px !important;
	right: 14px !important;
	cursor: pointer;
}

.success,
.error {
	display: flex;
	align-items: center;
	justify-content: start;
}

.status {
	margin: 0 10px;
	color: $color-white;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.message.side {
	margin: 8px 0 0 25px;
	color: $color-gray-lightest;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	text-align: justify;
}

.actions {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include respond-below(xs) {
		flex-wrap: wrap;
	}
}

.action {
	width: 48%;
	margin-top: 24px;
	padding: 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	border-radius: 6px;
	cursor: pointer;

	@include respond-below(xs) {
		width: 100%;
		margin-top: 10px;
	}
}

.action_disabled {
	cursor: default;

	@extend .action;
}

.cancel {
	color: $color-white;
	background: transparent;
	border: 1px solid #ffffff14;

	&:hover {
		background-color: $color-gray-dark;
		border: 1px solid $color-gray-light;
	}
}

.ok {
	color: $color-white;
	background: $color-green;
	border: 1px solid $color-green;

	&:hover {
		background-color: $color-green-dark;

		[class*='loader'] {
			&::after,
			&::before {
				background: $color-green-dark;
			}
		}
	}

	[class*='loader'] {
		background: $color-white;

		&::after,
		&::before {
			background: $color-green;
		}
	}
}

@include respond-below(xs) {
	[class*='ant-modal-centered'] {
		[class*='ant-modal-content'] {
			background: $color-white !important;
		}

		.cancel {
			color: #c9c9d8;
			border: 1px solid #c9c9d8;
		}

		.title {
			color: $color-black-dark;
		}

		.message {
			color: $color-gray-dark;
		}
	}
}
