@import 'src/assets/styles/mixins';

.heading {
	margin: 0;
	padding: 0 16px;
	color: $color-gray-text;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
}

.list {
	margin-bottom: 100px;
}

@include respond-below(xs) {
	.heading {
		display: none;
	}
}
