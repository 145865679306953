@import 'src/assets/styles/mixins';

.day {
	&_num {
		position: relative;
		padding: 16px;
		border-right: 1px solid #f0f3f6;
		border-bottom: 1px solid #f0f3f6;
	}

	&_plus {
		position: absolute;
		top: 10px;
		left: 10px;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	&_block {
		display: flex;
		justify-content: space-between;
	}

	&_future {
		cursor: pointer;

		&:hover {
			background-color: $color-service-orange;
		}
	}

	&_future:hover &_plus {
		display: flex;
		justify-content: space-between;
		opacity: 1;
	}

	&_date {
		color: $color-primary-dark;
		font-size: 20px;
		text-align: right;
	}

	&_today {
		background: $color-opacity-green;
	}

	&_another &_date {
		color: #373c464d;
	}

	&_month &_date {
		color: transparent;
	}

	&_downTimes {
		cursor: pointer;
		transition: background-color 0.3s ease-in-out;

		&:hover {
			background-color: $color-orange-downtimes;
		}
	}

	&_downTimes &_date {
		color: $color-red-danger;
	}

	&_actions {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 1;
		display: flex;
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

	&_downTimes:hover &_actions {
		opacity: 1;
	}

	&_edit:hover,
	&_show:hover {
		svg > path {
			fill: $color-black;
		}
	}

	&_reason {
		position: absolute;
		bottom: 5px;
		left: 0;
		z-index: 1;
		display: -webkit-box;
		align-self: center;
		height: max-content;
		max-height: 39px;
		padding: 4px 8px;
		overflow: hidden;
		color: $color-white;
		font-size: 11px;
		line-height: 16px;
		background: $color-red;
		opacity: 0.5;
		transition: opacity 0.5s ease-in-out;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		&:hover {
			opacity: 0.9;
		}
	}
}
