@import 'src/assets/styles/mixins';

.filter {
	padding: 20px;
}

.item {
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 12px;
	text-align: left;

	&:hover {
		color: $color-gray-dark;
	}

	[class*='inputText']:focus {
		outline: 1px solid $color-green;
	}
}

@include respond-below(xs) {
	.filter {
		padding: 0;
	}
}
