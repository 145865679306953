@import 'src/assets/styles/mixins';

.week {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.goods {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-right: 14px;
}

.quantity {
	margin-bottom: 4px;
	margin-left: -2px;
	font-weight: 700;
	font-size: 10px;
	line-height: 14px;
	text-align: center;
}

.dates {
	min-width: max-content;
	margin-right: 14px;
	margin-bottom: 17px;
	color: $color-typography-secondary;
	font-weight: 600;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-align: center;
}

.good {
	display: block;
	margin-right: 2px;
	padding: 8px 4px;
	background-color: $color-light-opacity-green;
	border-radius: 4px;
	transform: rotate(180deg);
	cursor: pointer;
	transition: background-color 0.3s ease-in-out;
	writing-mode: vertical-lr;

	&__blue {
		background-color: $color-light-opacity-blue;

		&:hover {
			background-color: $color-light-hover-blue;
		}
	}

	&__green {
		background-color: $color-light-opacity-green;

		&:hover {
			background-color: $color-light-hover-green;
		}
	}

	&_name {
		width: 15px;
		overflow: hidden;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&_barcode {
		overflow: hidden;
		color: #1a1a1a80;
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		white-space: nowrap;
		text-overflow: ellipsis;

		span {
			font-weight: 600;
			line-height: 16px;
		}
	}
}

.border {
	display: flex;
	height: 14px;
	margin-top: 4px;
	margin-right: 14px;
	border-top: 1px solid $color-schedule-week-border;

	&__add {
		width: 50%;
		height: 13px;
		border-right: 1px solid $color-schedule-week-border;
	}
}
