@import 'assets/styles/mixins';

.groups {
	min-height: 60vh;
	margin-bottom: 100px;
	padding: 12px;
	background: $color-white;
}

.group {
	padding: 12px 0 12px 12px;
	transition: left 0.1s ease-in-out;

	&_body {
		margin: 12px;
		border: 1px dashed #d8d8e2;
	}

	&_last {
		border-bottom: 1px solid #d8d8e2;
	}
}
