@import 'assets/styles/mixins';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 7000;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;

	&_body {
		position: relative;
		width: 420px;
		padding: 16px;
		color: $color-white;
		background: #000000bd;
		border-radius: 12px;
		box-shadow: 0 15px 30px 0 #0000001a;
		backdrop-filter: blur(24px);
	}

	&_title {
		padding-top: 28px;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
	}

	&_description {
		margin: 16px auto 32px;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
	}

	&_actions {
		display: flex;
		align-items: center;
	}
}

.close {
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;

	svg > path {
		transition: fill 0.3s linear;
	}

	&:hover svg > path {
		fill: $color-white;
	}
}
