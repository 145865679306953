@import 'src/assets/styles/global';

.box {
	@extend %flex;
	padding: 0 16px;
	font-weight: 400;
	font-size: 9px;
	line-height: 16px;
	background: $color-gray-grouping;

	&__right {
		flex: 1;
		width: 100%;

		&_text {
			padding-left: 7px;
			text-transform: uppercase;
		}
	}

	&__center {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 0;
		transition: all 0.5s ease-in-out;
	}
}
