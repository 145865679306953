@import 'src/assets/styles/mixins';

.content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 60px;

	@include respond-below(md) {
		padding: 0 16px;
	}
}

.table {
	width: 50%;
	height: max-content;
	background-color: $color-grayer;
	border: 1px solid $color-border-gray;
	border-radius: 8px;

	&_text {
		color: $color-text-name;
		font-size: 12px;
	}

	&_files {
		border: none;
	}

	@include respond-below(lg) {
		width: 100%;
	}
}

.table:first-child {
	margin-bottom: 130px;

	@include respond-below(md) {
		margin-bottom: 45px;
	}
}

.files {
	width: 48%;

	&_form {
		position: relative;
		padding: 34px 16px;
		border: 1px solid $color-border-gray;
		border-radius: 5px;

		&__label {
			position: absolute;
			top: -11px;
			left: 10px;
			display: inline-block;
			padding: 0 5px;
			color: $color-text-name;
			font-weight: 700;
			font-size: 12px;
			background-color: $color-grayer;
		}
	}

	&_result {
		margin-bottom: 16px;
		padding: 22px;
		color: $color-files-result;
		font-weight: 700;
		font-size: 12px;
		text-align: center;
		background-color: $color-files-gray;
		border-radius: 3px;
	}

	&_text {
		padding: 30px 16px 16px;
		color: $color-text-name;
		font-weight: 500;
		font-size: 14px;
		background-color: inherit;
	}

	&_add {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: start;

		&_input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 165px;
			padding: 10px 20px;
			opacity: 0;
		}

		&_button {
			margin-right: 20px;
			padding: 10px 20px;
			color: $color-gray-dark;
			font-weight: 600;
			font-size: 16px;
			border: 1px solid #dedee3;
			border-radius: 3px;
			cursor: pointer;

			&:hover {
				background: $color-gray-light;
			}
		}

		&_text {
			color: $color-black-dark;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
		}
	}

	@include respond-below(md) {
		width: 100%;
	}
}
