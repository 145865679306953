@import 'src/assets/styles/global';

.modal {
	position: fixed;
	right: 16px;
	z-index: 7000;
}

.body {
	position: relative;
	width: 330px;
	min-height: 96px;
	padding: 16px;
	color: #ffffff80;
	background: #000000bd;
	border-radius: 12px;
	box-shadow: 0 15px 30px 0 #0000001a;
	backdrop-filter: blur(24px);

	&_status {
		display: flex;
		align-items: center;
		justify-content: start;
		margin-bottom: 8px;
	}

	&_text {
		margin-left: 11px;
		color: $color-white;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}

	&_content {
		padding-left: 36px;
	}

	&_content span {
		color: $color-white;
		font-weight: 700;
	}
}

.close {
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
}
