@import 'src/assets/styles/mixins';

.clean {
	position: relative;

	&__active {
		border-left-color: $color-primary-dark-opacity;

		svg > path {
			fill: $color-white;
		}
	}

	&_button {
		box-sizing: content-box;
		padding: 9px 10px;
		border-left: 1px solid $color-service-grey;
		cursor: pointer;

		svg > path {
			fill: $color-green;
		}
	}

	&_disable {
		svg > path {
			fill: $color-middle-gray;
		}
	}

	&_active {
		svg > path {
			fill: $color-white;
		}
	}

	&_hint {
		position: absolute;
		width: max-content;
		color: #c3cad3;
		font-weight: 500;
		font-size: 12px;
		visibility: hidden;
		opacity: 0;
		transition: all 0.6s ease;
	}

	&:hover &_hint {
		visibility: visible;
		opacity: 1;
	}
}

@include respond-above(xs) {
	.clean {
		&:hover {
			svg > path {
				fill: $color-white;
			}
		}

		&_button {
			&:hover {
				svg > path {
					fill: $color-green;
				}
			}
		}

		&_disable {
			&:hover {
				svg > path {
					fill: $color-middle-gray;
				}
			}
		}

		&_active {
			&:hover {
				svg > path {
					fill: $color-white;
				}
			}
		}
	}
}

@include respond-below(xs) {
	.clean {
		&_hint {
			display: none;
		}

		&_button {
			display: none;
			padding: 9px 0 9px 8px;
			border-left: none;

			svg {
				width: 18px;
				height: auto;
			}
		}

		&__active {
			border-left: none;

			svg > path {
				fill: $color-green;
			}

			&:hover {
				svg > path {
					fill: $color-middle-gray;
				}
			}
		}

		&_disable {
			svg > path {
				fill: $color-middle-gray;
			}
		}
	}
}
