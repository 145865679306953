@import 'assets/styles/mixins';

.orderRow {
	z-index: 1;
	display: flex;
	background-color: $color-white;
	cursor: pointer;
	transition: background-color 0.5s ease-in-out;

	&_td {
		display: flex;
		align-items: center;
		padding: 6px;
		font-weight: 500;
		font-size: 11px;
		border-right: 1px solid $color-border-gray;

		&:first-child {
			border-left: 1px solid $color-border-gray;
		}
	}

	&:hover {
		background-color: #f9f9fca3;
	}
}

.checkmark {
	margin-left: -4px;
}

.wrLeft {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 55px;
}
