@import 'src/assets/styles/mixins';

.order {
	display: flex;
	align-items: center;
	height: 36px;
	margin-left: 5px;
	padding: 10px 16px;
	font-size: 12px;
	background: $color-service-grey;
	border-radius: 5px;
	cursor: pointer;

	&:hover {
		background-color: $color-gray-light;
	}

	&__close {
		display: flex;
		align-self: center;
		margin-left: 10px;

		&:hover > svg > path {
			fill: $color-green-dark;
		}
	}

	&__number {
		display: flex;
		align-items: center;
		width: 100%;
		height: inherit;
		padding-top: 2px;

		a {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	&__active {
		color: $color-white;
		background: $color-green;

		&:hover {
			background: $color-green-dark;
		}
	}

	&__active &__close > svg > path {
		fill: $color-white;
	}
}
