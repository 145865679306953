@import 'src/assets/styles/mixins';

.card {
	max-height: max-content;
	padding-bottom: 24px;
	border-bottom: 1px solid $color-gray-lightest;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;

	&_title {
		margin-bottom: 16px;
		padding: 24px 16px 0;
		color: $color-primary-dark;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
	}

	&_hidden {
		opacity: 0.4;

		&_action {
			cursor: unset;
		}
	}

	&:last-child {
		border-bottom: none;
	}

	&_actions {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 32px;
		padding: 0 16px;
	}

	&_action {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-right: 18px;
		color: #8a9099;
		font-size: 12px;
		cursor: pointer;
		transition: color 0.3s ease-in-out;

		svg > g > path {
			transition: fill 0.3s ease-in-out;
		}

		&:hover {
			color: $color-primary-dark;

			svg > g > path {
				fill: $color-primary-dark;
			}
		}
	}

	&_label,
	&_text {
		padding: 0 16px;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	&_label {
		margin-bottom: 6px;
		color: $color-base-grey;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
	}

	&_text {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}

	&_block {
		margin-bottom: 16px;
	}

	&_icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 3px;
	}
}
