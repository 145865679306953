@import 'assets/styles/mixins';

.doc {
	width: 32%;
}

@include respond-below(xxl) {
	.doc {
		width: 48%;
	}
}

@include respond-below(sm) {
	.doc {
		width: 100%;
	}
}
