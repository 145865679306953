@import 'src/assets/styles/mixins';

.download {
	width: max-content;
	padding: 4px 12px;
	color: $color-white;
	font-size: 12px;
	background-color: $color-green;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.5s linear;

	&:hover {
		background-color: $color-green-dark;
	}
}
