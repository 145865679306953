@import 'src/assets/styles/mixins';

.userMenu {
	background-color: $color-white;

	&_content {
		height: calc(100vh - 160px);
		overflow-y: auto;
		background-color: $color-white;
		scrollbar-width: thin;
		scrollbar-color: $scroll-color $scroll-field-color;
	}
}

.header {
	padding: 20px;
	border-bottom: 1px solid $color-gray-light;
}

.title {
	color: $color-black-dark;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
}

.id {
	color: $color-text-name;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
}

.list {
	display: flex;
	flex-flow: column wrap;

	&__item {
		display: inline-flex;
		align-items: center;
		margin: 0;
		padding: 20px;
		color: $color-gray-text;
		font-size: 12px;
		border-bottom: 1px solid $color-gray-light;
		cursor: pointer;

		&:hover {
			background-color: $color-grayer;
		}

		&_text {
			display: inline-block;
			margin-left: 9px;
		}
	}

	&__logout {
		margin: 0;
		font-weight: 700;
	}

	&__support {
		margin: 0;
		padding: 0;
		text-align: center;
		border-radius: 4px;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.8s, visibility 0.8s, margin 0.8s;

		&_success {
			color: $color-green-dark;
			background-color: $color-green-white;
		}

		&_warning {
			color: $color-orange;
			background-color: $color-orange-opacity;
		}

		&_success,
		&_warning {
			margin: 20px 0 0;
			padding: 10px;
			font-size: 12px;
			line-height: 12px;
			visibility: visible;
			opacity: 1;

			@include respond-below(sm) {
				margin: 0;
			}
		}
	}
}

.phone {
	padding: 0 20px;
	color: $color-gray-text;
	font-weight: bold;
	font-size: 12px;

	&_text {
		margin-bottom: 0;
		color: $color-black-dark;
	}

	&_link {
		color: $color-green;
		cursor: pointer;
	}

	&_time {
		margin-top: 0;
		font-weight: normal;
	}
}

@include respond-below(md) {
	.header {
		padding: 32px 16px 16px;
	}

	.title {
		color: $color-black;
		font-size: 14px;
	}

	.list {
		margin-top: 16px;
	}

	.list__item {
		padding: 8px 16px;
		font-size: 12px;
		border-bottom: 0;
	}
}
