@import 'src/assets/styles/mixins';

.events {
	background: $color-white;

	@include content;
	@include header;

	@include respond-below(xs) {
		background-color: $color-grayer;
	}
}
