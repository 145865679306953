@import 'src/assets/styles/mixins';

.icons {
	display: flex;
	align-items: center;
	padding-right: 20px;
}

@include respond-below(xs) {
	.icons {
		flex: 1;
		justify-content: end;
		padding-right: 16px;
	}
}
