@import 'src/assets/styles/mixins';

.button {
	flex: 1 1;
	padding: 20px;
	color: $color-gray;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	cursor: pointer;

	p {
		margin: 0;
	}

	&:hover {
		color: $color-gray-dark;
	}
}

@include respond-below(xs) {
	.button {
		padding: 16px;
		font-size: 12px;
	}
}
