@import 'assets/styles/mixins';

.main {
	display: flex;
	align-items: center;
	justify-content: start;
}

.title {
	margin: 0 5px;
	color: $color-primary-dark;
	font-weight: 700;
	font-size: 12px;
}

.value {
	color: $color-black;
	font-weight: 500;
	font-size: 12px;
}

.count {
	margin-left: 5px;
	color: $color-gray-dark;
	font-weight: 400;
	font-size: 11px;
}

.check {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	margin-top: -2px;
	color: $color-white;
	background: $color-check-group;
	border-radius: 3px;
	cursor: pointer;

	span {
		display: inline-block;
		margin-top: -2px;
		font-size: 10px;
	}
}
