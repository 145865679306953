@import 'src/assets/styles/mixins';

.pagination {
	position: fixed;
	bottom: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 20px 16px;
	background: $color-white;
	box-shadow: 0 8px 32px 0 #0c10181f;
	transition: margin-bottom 0.3s ease-in-out;

	&__hidden {
		visibility: hidden;
		opacity: 0;
	}
}

.text {
	color: $color-gray-text;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
}

.pages {
	display: flex;
	flex: 1;
	justify-content: center;
}

.page {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	margin-right: 6px;
	font-size: 12px;
	text-align: center;
	border: 1px solid $color-gray-light;
	border-radius: 3px;
	cursor: pointer;

	&:hover {
		background-color: $color-gray-light;
	}

	@include respond-below(xl) {
		margin-bottom: 10px;
	}
}

.current {
	color: $color-green-text;
	background-color: $color-green-opacity;
}
