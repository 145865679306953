@import 'src/assets/styles/global';

.container {
	width: 350px;
	height: 350px;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	&__header {
		@include auth-header;

		@include respond-below(xs) {
			display: none;
		}
	}

	&__password {
		position: relative;
	}

	&__eye {
		position: absolute;
		top: 20px;
		right: 10px;
		cursor: pointer;

		svg > path {
			transition: fill 0.3s ease-in-out;
		}

		&:hover svg > path {
			fill: $color-black;
		}

		@include respond-below('xs') {
			top: 17px;
		}
	}

	&__input {
		display: inline-block;
		box-sizing: border-box;
		width: 100%;
		margin: 8px 0 0;
		padding: 12px 20px;
		background: $color-service-grey;
		border: 1px solid $color-gray-light;
		border-radius: 8px;

		&:focus {
			border-radius: 5px;
			outline: 1px solid $color-gray-light;
		}

		&::placeholder {
			color: $color-gray-placeholder;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
		}

		@include respond-below('xs') {
			margin: 3px 0 15px;
			font-size: 16px;

			&:focus {
				outline: 0;
				outline-offset: 0;
			}

			&::placeholder {
				font-size: 14px;
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: center;
		margin-top: 25px;

		@include respond-below('xs') {
			flex-wrap: wrap;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		width: max-content;
		padding: 12px 20px;
		color: $color-white;
		font-weight: 600;
		background-color: $color-green;
		border: 1px solid $color-green;
		border-radius: 8px;
		cursor: pointer;
		transition: all 0.8s ease;

		&:hover {
			box-shadow: 0 0 3px 0 $color-green;
		}

		@include respond-below('xs') {
			width: 100%;
			margin-bottom: 10px;
		}
	}

	&__button_error {
		color: $color-white;
		background-color: $color-gray;
		border: 1px solid $color-gray;
		cursor: default;
	}

	&__button_error:hover {
		color: $color-white;
		border: 1px solid $color-gray;
		box-shadow: 0 0 3px 0 $color-gray;
	}

	&__button_loading {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__button_remember {
		width: 100%;
		margin-top: 40px;
		color: $color-green;
		font-weight: 500;
		background-color: inherit;
		border: none;

		@include respond-below('xs') {
			margin-top: 6px;
		}
	}

	&__error {
		display: flex;
		margin: 0;
		padding: 0;
		color: $color-red;
		transform: translateY(100px);
		visibility: hidden;
		opacity: 0;
		transition: all 0.5s ease;

		&_icon {
			margin-right: 10px;
		}

		&_visible {
			margin-top: 20px;
			margin-bottom: 30px;
			padding: 12px 15px;
			border: 1px solid #d9464633;
			border-radius: 5px;
			transform: translateY(0);
			visibility: visible;
			opacity: 1;
		}
	}

	@include auth-center;
}

@include respond-below(xs) {
	.container {
		width: 100%;
	}

	.form {
		width: 90%;
	}
}
