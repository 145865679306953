@import 'assets/styles/mixins';

.links {
	position: absolute;
	bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 20px;
	font-weight: 400;

	&_group {
		display: flex;
		align-items: center;
	}

	&_item {
		padding: 3px 0;
		color: $color-base-grey;
		font-size: 13px;
		line-height: 16px;
		cursor: pointer;
		transition: filter 0.3s ease-in-out;

		&:not(:first-child) {
			padding-right: 8px;
			padding-left: 8px;
			border-left: 1px solid $color-base-grey;
		}

		&:first-child {
			padding-right: 8px;
		}

		&:hover {
			filter: brightness(60%);
		}
	}

	&_copyright {
		padding: 3px 0;
		color: $color-middle-gray;
		font-size: 13px;
	}
}

@include respond-below(xxl) {
	.links {
		&_group {
			display: block;
		}

		&_item {
			padding: 0;

			&:not(:first-child) {
				padding: 0;
				border: none;
			}

			&:first-child {
				padding: 0;
			}
		}

		&_copyright {
			align-self: flex-end;
			padding: 0;
		}
	}
}

@include respond-below(md) {
	.links {
		display: block;
	}
}
