@import 'src/assets/styles/mixins';

.mobile {
	display: flex;
	justify-content: space-between;

	@include respond-below(xs) {
		width: 100%;
	}
}
