@import 'src/assets/styles/mixins';

.group {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;

	&__text {
		width: max-content;
		margin-left: 8px;
		color: $color-black;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
	}

	@media (max-width: 1280px) {
		display: none;
	}
}
