@import 'assets/styles/mixins';

.noResult {
	display: flex;
	flex-direction: column;
	justify-content: center;

	&_image {
		text-align: center;
	}

	&_text {
		color: $color-typography-secondary;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
	}
}
