@import 'src/assets/styles/mixins';

@keyframes sticky {
	0% {
		transform: translateY(-15px);
	}

	100% {
		transform: translateY(0);
	}
}

.headerWithAuth {
	width: 100%;
	background-color: $color-white;

	.logoGroup {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.headerSticky {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	box-shadow: $shadow;
	transition: all 0.5s ease;
	animation: sticky 0.5s ease;
}
