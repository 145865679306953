@import 'src/assets/styles/mixins';

.footer {
	position: fixed;
	bottom: 0;
	z-index: 10;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: $color-gray-darkest;
	border-color: $color-grayer;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.3s ease, opacity 0.6s ease;

	&_visible {
		visibility: visible;
		opacity: 1;
	}

	&_actions {
		display: flex;
		flex-wrap: wrap;
	}

	&_copyright {
		padding: 15px;
		color: #747880;
		font-size: 12px;

		&:hover {
			color: $color-white;
		}
	}

	&_fixed {
		position: fixed;
	}
}

@include respond-below(xs) {
	.footer {
		display: block;
		padding: 0;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;

		&_copyright {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #ffffff0d;
		}

		&_actions {
			display: block;
		}

		&_action {
			color: $color-green;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
		}
	}
}
