@import 'src/assets/styles/mixins';

.filterList {
	position: relative;
	z-index: 2;
	width: 330px;
	margin-top: 10px;
	background: $color-white;
	border-radius: 8px;
	box-shadow: $shadow;

	&_orders {
		width: 500px;
	}
}

.header {
	display: none;
	padding: 20px 20px 10px;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	border-bottom: 1px solid #e4e4ef;
}

.body {
	border-bottom: 1px solid #e4e4ef;
}

.action {
	display: flex;
	justify-content: space-between;
}

@include respond-below(xs) {
	.filterList {
		z-index: 10;
		width: 100%;
		padding-bottom: 20px;
		background-color: $color-grayer;
		box-shadow: none;
	}

	.header {
		display: block;
		padding: 20px 0 10px;
		font-size: 14px;
	}

	.body {
		margin-right: -16px;
		margin-left: -16px;
		padding: 16px 16px 50px;
		background-color: $color-grayer;
		border-bottom: none;
	}
}
