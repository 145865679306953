@import 'assets/styles/mixins';

.apiLink {
	display: inline-flex;
	align-items: center;
	padding: 20px;
	color: $color-gray-text;
	font-size: 12px;
	border-bottom: 1px solid $color-gray-light;
	cursor: pointer;

	&__text {
		display: block;
		margin-left: 9px;
	}

	&:hover {
		background-color: $color-grayer;
	}

	@include respond-below(md) {
		padding: 8px 16px;
		border-bottom: 0;
	}
}
