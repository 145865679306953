@import 'src/assets/styles/mixins';

.actions {
	display: block;
	border-top: 1px solid #e4e4ef;

	&__top {
		display: flex;
		justify-content: space-between;
	}

	&__bottom {
		display: none;
	}

	&__confirm {
		width: 50%;
		border-right: 1px solid #e4e4ef;

		p {
			color: $color-green;
		}

		&:hover p {
			color: $color-green-dark;
		}
	}

	&__clear {
		width: 50%;
	}

	&__disable {
		color: $color-gray;

		&:hover {
			color: $color-gray;
		}
	}
}

@include respond-below(xs) {
	.actions {
		position: fixed;
		bottom: 16px;
		width: 100%;
		margin: -16px;
		padding-top: 0;
		background-color: $color-gray-darkest;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;

		&__top {
			border-bottom: 1px solid #ffffff0d;
		}

		&__bottom {
			display: flex;
			justify-content: center;

			[class='botton'] {
				text-align: center;
			}
		}

		&__confirm {
			width: 50%;
			border-right: 1px solid #ffffff0d;

			p {
				color: $color-green;
			}
		}

		&__clear {
			width: 50%;

			p {
				color: $color-red-blur;
			}
		}
	}
}
