@import 'assets/styles/mixins';

.message {
	position: fixed;
	top: auto;
	bottom: 20px;
	z-index: 1000;
	padding: 6px 20px;
	color: $color-gray;
	font-size: 14px;
	background-color: $color-red-blur;
	border-radius: 5px;
	transform: translateX(-200px);
	visibility: hidden;
	opacity: 0;
	transition: all 0.8s ease-in-out;

	&__offline {
		left: 20px;
		transform: translateX(0);
		visibility: visible;
		opacity: 1;
	}

	@include respond-below(xs) {
		top: 20px;
		bottom: auto;
	}
}
