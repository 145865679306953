@import 'src/assets/styles/mixins';

.actions {
	padding: 0;

	&_editOrders {
		border-top: none;
	}
}

.back {
	display: flex;
	align-items: center;

	&_arrow {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 16px;
		height: 16px;
		margin-right: 4px;
		background: $color-green;
		border-radius: 50%;
		transform: rotate(90deg);
	}
}

@include respond-above(xs) {
	.actions {
		background: $color-white;
		border-top: 1px solid $color-gray-light;

		&_multiEdit {
			border-top: none;
		}
	}
}
