@import 'assets/styles/mixins';

%flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cookies {
	position: fixed;
	right: 20px;
	bottom: 52px;
	left: 20px;
	padding: 12px;
	color: $color-white;
	background: $color-gray-darkest;
	border-radius: 12px;
	box-shadow: 0 8px 40px 0 #00000014;
	transition: opacity 0.3s ease-in-out;

	@extend %flex;

	&_info {
		flex: 1;
	}

	&_visible {
		z-index: 1000;
		transform: translateX(0);
		opacity: 1;
	}

	&_icon,
	&_close {
		display: none;
	}

	&_hided {
		z-index: 0;
		transform: translateX(-2000px);
		opacity: 0;
	}

	&_image {
		margin-right: 12px;
	}

	&_title {
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
	}

	&_text {
		width: 80%;
		padding-right: 10px;
		color: $color-tertiary;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		text-align: justify;

		&__file {
			color: $color-green;
		}

		&__link {
			text-decoration: underline;
			cursor: pointer;
			transition: color 0.3s ease-in-out;

			&:hover {
				color: $color-white;
			}
		}
	}

	&_button {
		padding: 8px 16px;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		border-radius: 6px;
		cursor: pointer;
		transition: all 0.4s ease-in-out;

		&__disable {
			margin-right: 8px;
			background: transparent;
			border: 1px solid $color-tertiary;

			&:hover {
				background: $color-primary-dark-opacity;
			}
		}

		&__confirm {
			background: $color-green;
			border: 1px solid $color-green;

			&:hover {
				background: $color-green-opacity;
			}
		}
	}

	&_content {
		@extend %flex;
	}

	&_actions {
		@extend %flex;
	}
}

@include respond-below(lg) {
	.cookies {
		bottom: 80px;
	}
}

@include respond-below(xs) {
	.cookies {
		right: -2px;
		bottom: -2px;
		left: 0;
		padding: 20px 15px;
		border-radius: 18px 18px 0 0;

		&_image {
			display: none;
		}

		&_title {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&_header {
			flex: 1;
			padding-left: 10px;
			text-align: left;
		}

		&_content {
			flex-wrap: wrap;
			padding-top: 16px;
		}

		&_button {
			flex: 1;
			text-align: center;
		}

		&_text,
		&_actions {
			width: 100%;
		}

		&_actions {
			justify-content: center;
			margin: 16px 0 32px;
		}

		&_icon,
		&_close {
			display: flex;
			align-items: center;
		}

		&_close {
			cursor: pointer;

			&:hover svg > path {
				fill: $color-white;
			}
		}
	}
}
