@import 'src/assets/styles/mixins';

.message {
	position: absolute;
	top: 16px;
	right: 0;
	left: 0;
	transition: opacity 0.5s linear, color 0.5s linear;

	&_hidden {
		color: transparent;
		opacity: 0;
	}

	&_error {
		padding: 6px 20px;
		color: $color-white;
		font-size: 12px;
		background-color: $color-red;
		border-radius: 5px;
		opacity: 1;
	}

	&_success {
		padding: 6px 20px;
		color: $color-white;
		font-size: 12px;
		background-color: $color-green;
		opacity: 1;
	}
}
