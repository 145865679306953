@import 'src/assets/styles/mixins';

.panel {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16px;

	&_actions {
		display: flex;
	}

	&_left {
		flex: 1;
		justify-content: flex-start;
	}

	&_right {
		flex: 1;
		justify-content: flex-end;
	}

	&_center {
		width: max-content;
		background-color: $color-grayer;
		border: 2px solid $color-service-grey;
		border-radius: 8px;
	}
}

.export {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
	color: $color-primary-dark;
	font-weight: 600;
	font-size: 11px;
	border: 1px solid $color-service-grey;
	border-radius: 8px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	span {
		margin-left: 10px;
	}

	&:hover {
		border: 1px solid $color-light-green;
		box-shadow: 0 0 0 2px #8dc64040;

		svg > path {
			stroke: $color-green-dark;
		}
	}
}

@include respond-below(md) {
	.panel {
		&_left {
			flex: unset;
		}

		&_right {
			flex: unset;
		}
	}
}

@include respond-below(xs) {
	.panel {
		&_center {
			flex: 1;
		}
	}

	.events {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 16px;
		border: 1px solid $color-green;
		border-radius: 3px;
	}
}

@include respond-below(xxs) {
	.events {
		flex-wrap: wrap;
	}
}
