@import 'src/assets/styles/mixins';

.table {
	width: 100%;
}

.th {
	padding: 4px;
	color: $color-gray-dark;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	box-shadow: -1px -1px 0 0 #e4e4ef inset;
}

.td {
	padding: 5px;
	font-size: 10px;
	text-align: center;
	box-shadow: -1px -1px 0 0 #e4e4ef inset;
}

thead .tr,
.tr:nth-child(even) {
	background-color: $color-gray-table;
}
