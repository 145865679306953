@import 'src/assets/styles/mixins';

.filter {
	display: flex;
	height: 36px;
	background-color: transparent;
	border: 1px solid $color-service-grey;
	border-radius: 8px;
	transition: border 0.3s ease, box-shadow 0.3s ease;

	&:hover {
		border: 1px solid $color-green;
		box-shadow: $shadow-button;
	}

	&__active {
		background-color: $color-green;
		border: 1px solid $color-green;
	}
}

@include respond-below(xs) {
	.filter {
		background: transparent;
		border: none;

		&:hover {
			border: none;
			box-shadow: none;
		}

		&__events {
			padding: 0;
		}
	}
}
