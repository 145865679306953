@import 'src/assets/styles/mixins';

.status {
	display: inline-block;
	width: max-content;
	padding: 5px;
	color: $color-green-dark;
	font-size: 10px;
	text-transform: uppercase;
	background: $color-green-opacity;
	border: 1px solid $color-green-dark;
	border-radius: 3px;

	&.error {
		color: $color-red;
		background: $color-red-opacity;
		border: 1px solid $color-red;
	}

	&.warning {
		color: $color-orange;
		background: $color-orange-opacity;
		border: 1px solid $color-orange;
	}

	&.default {
		color: $color-gray-dark;
		background: $color-gray-light;
		border: 1px solid $color-gray-dark;
	}

	&.detail {
		padding: 0 5px;
	}

	&.multiEdit {
		padding: 0 5px;
		line-height: 18px;
	}
}
