@import 'src/assets/styles/mixins';

.settings {
	max-width: 570px;
	margin-left: 60px;
}

@include respond-below(md) {
	.settings {
		width: 100%;
		margin-left: 0;
		padding: 0 16px;
	}
}
