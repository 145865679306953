@import 'src/assets/styles/mixins';

.property {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	font-size: 12px;
	line-height: 20px;

	&__quantity {
		padding-top: 0;
		padding-bottom: 0;

		input {
			padding-right: 0;
			padding-left: 0;
		}

		&_active {
			background: $color-green-white;
		}
	}
}

.property:not(:first-child) {
	box-shadow: 0 -1px 0 0 #f1f1f7 inset;
}

.name {
	display: flex;
	width: 50%;
	color: $color-text-name;
}

.value {
	display: flex;
	align-items: center;
	color: $color-black;
	text-align: right;
}
