@import 'src/assets/styles/mixins';

.message {
	padding: 16px;
	color: $color-gray-dark;
}

@include respond-below(xs) {
	.message {
		padding: 16px 0;
		font-size: 12px;
	}
}
