@import 'src/assets/styles/mixins';

.filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	padding: 16px;
}

.left,
.right {
	width: 48%;
}

.item {
	display: block;
	width: 100%;
	margin-bottom: 12px;
	font-weight: 500;
	font-size: 12px;
	text-align: left;
	opacity: 1;
	transition: opacity 0.1s linear, margin-bottom 0.3s ease;

	&_label {
		display: block;
		margin-bottom: 8px;
		color: $color-base-grey;
	}

	&_liner {
		display: block;
		margin-top: -12px;
		margin-bottom: 12px;
		transition: margin 0.3s ease, height 0.3s ease-in-out;
	}

	[class*='inputText']:focus {
		outline: 1px solid $color-green;
	}

	&_line {
		display: flex;
		justify-content: space-between;

		[class*='ant-picker-focused'] {
			background: $color-white;
			border: 1px solid $color-green;
			box-shadow: none;
		}

		[class*='ant-picker']:not([class*='ant-picker-input'], [class*='ant-picker-focused']):hover {
			border: 1px solid $color-service-grey;
		}

		&__item {
			width: 49%;
			height: 36px;
			padding-right: 5px;
			background: $color-service-grey;
			border: 1px solid $color-service-grey;
			border-radius: 3px;

			[class*='ant-picker-input'] input {
				top: 1px;
				font-size: 12px;
			}

			&:first-child {
				[class*='ant-picker-input'] input {
					margin-left: 15px;
				}
			}

			&:last-child {
				[class*='ant-picker-input'] input {
					margin-left: 20px;
				}
			}

			&:first-child::before,
			&:last-child::before {
				position: absolute;
				left: 10px;
				display: block;
				color: #c3cad3;
				font-size: 12px;
			}

			&:first-child::before {
				content: attr(title);
			}

			&:last-child::before {
				content: attr(title);
			}
		}
	}
}

.active::after {
	position: absolute;
	top: 44%;
	left: 4px;
	display: block;
	width: 5px;
	height: 5px;
	background: $color-green;
	border-radius: 50%;
	content: '';
}

.active.eng::after {
	left: 2px;
}

.eng {
	&:first-child {
		[class*='ant-picker-input'] input {
			margin-left: 25px;
		}
	}

	&:first-child::before {
		left: 7px;
	}

	&:last-child {
		[class*='ant-picker-input'] input {
			margin-left: 15px;
		}
	}
}

.isHidden {
	display: block;
	max-height: 0.1px;
	margin-bottom: 0;
	opacity: 0;

	[class*='input'] {
		height: 0.1px;
	}
}

.isDisable {
	display: none;
}

@include respond-below(xs) {
	.filter {
		padding: 0;
	}

	.left,
	.right {
		width: 100%;
	}

	.item {
		&_liner {
			display: none;
		}
	}
}
