@import 'src/assets/styles/mixins';

.week {
	position: relative;
	display: flex;
	justify-content: space-between;

	&_groups {
		position: absolute;
		bottom: 0;
		width: 100vw;
	}
}
