@import 'src/assets/styles/mixins';

.filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
}

.item {
	width: 100%;
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 12px;
	text-align: left;

	&_label {
		display: block;
		margin-bottom: 20px;
		font-weight: 700;
	}

	&_line {
		display: flex;
		justify-content: space-between;

		&__item {
			width: 48%;
			height: 36px;
			background: #c3cad32e;
			border-radius: 3px;

			[class='ant-picker-suffix'] {
				display: none;
			}

			[class*='ant-picker-input'] input {
				padding-top: 2px;
				font-size: 12px;
			}

			&:first-child::before,
			&:last-child::before {
				display: block;
				margin-right: 8px;
				color: #c3cad3;
				font-size: 12px;
			}

			&:first-child::before {
				content: attr(title);
			}

			&:last-child::before {
				content: attr(title);
			}
		}
	}

	&:hover {
		color: $color-gray-dark;
	}
}

@include respond-below(xs) {
	.filter {
		padding: 0;
	}

	.item {
		width: 100%;
	}
}
