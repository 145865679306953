@import 'assets/styles/global';

.export {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
	color: $color-primary-dark;
	font-weight: 600;
	font-size: 11px;
	border: 1px solid $color-service-grey;
	border-radius: 8px;
	cursor: pointer;
	transition: all 0.5s ease-in-out;

	span {
		margin-left: 10px;
	}

	&:hover {
		border: 1px solid $color-light-green;
		box-shadow: 0 0 0 2px #8dc64040;

		svg > path {
			stroke: $color-green-dark;
		}
	}

	&__disabled:hover {
		border: 1px solid $color-service-grey;
		box-shadow: none;
		cursor: default;

		svg > path {
			stroke: $color-export-icon-gray;
		}
	}
}

.close,
.confirm {
	flex: 1;
	height: 42px;
	padding: 12px;
	line-height: normal;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
}

.close {
	margin-right: 5px;
	background: transparent;
	border: 1px solid #ffffff14;
}

.confirm {
	margin-left: 5px;
	background: $color-green;
	border: 1px solid $color-green;
}
