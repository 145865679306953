@import 'src/assets/styles/mixins';

.logo {
	display: flex;
	align-items: center;
	padding: 20px 0 20px 20px;
	cursor: pointer;

	&_image {
		display: flex;
		align-items: center;
		padding: 6px 16px 0 0;
		border-right: 1px solid $color-gray;

		svg {
			width: 180px;
			height: 34px;

			& > path {
				transition: fill 1s;
			}
		}

		&__eng {
			justify-content: flex-start;

			svg {
				width: auto;
				height: auto;

				& > path {
					transition: fill 1s;
				}
			}
		}

		&:hover svg > path {
			fill: $color-green-dark;
		}

		@media (max-width: 320px) {
			border-right: none;
		}
	}

	&_text {
		width: 120px;
		padding: 8px 0 6px 16px;
		color: $color-black;
		font-size: 11px;
		line-height: 13px;

		@include respond-below(sm) {
			width: unset;
		}

		&__desktop {
			@include respond-below(xs) {
				display: none;
			}
		}

		&__mobile {
			@include respond-above(xs) {
				display: none;
			}
		}
	}

	&_phone {
		color: $color-green;
		font-weight: 700;
		font-size: 14px;

		&:hover {
			color: $color-green-dark;
		}

		&__novisible {
			display: none;
		}
	}

	@include respond-below(sm) {
		&_phone {
			display: none;
		}
	}

	@include respond-below(xs) {
		justify-content: center;
		padding-left: 16px;

		&_image {
			padding-right: 8px;

			svg {
				width: 96px;
				height: 18px;
			}
		}

		&_text {
			display: flex;
			align-items: center;
			width: 106px;
			padding-top: 4px;
			padding-left: 8px;
			font-size: 10px;
			line-height: 10px;

			@include respond-below(sm) {
				width: unset;
			}

			@media (max-width: 320px) {
				display: none;
			}
		}
	}
}
