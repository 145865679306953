@import 'src/assets/styles/mixins';

.button {
	border-right: 0 !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.events {
	margin-top: -1px;
}

@include respond-below(xs) {
	.button {
		width: unset;
		border: none;
	}

	.events {
		margin-right: 0;
	}
}
