@import 'src/assets/styles/mixins';

.goodsList {
	width: 100%;
	padding: 30px 16px 80px;

	[class*='load'] {
		top: calc(50% + 200px);
	}
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	margin-bottom: 16px;
	color: $color-black-dark;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;

	&_number {
		color: $color-green;
	}
}

.tab {
	display: flex;
	align-items: center;
	color: $color-green;
	font-size: 14px;
	cursor: pointer;

	&_arrow {
		display: inline-block;
		margin-left: 6px;
	}

	&_cancel {
		margin-right: 30px;
	}

	&_text,
	&_cancel {
		&:hover {
			color: $color-green-dark;
		}
	}
}

.rotate {
	svg {
		transform: rotate(180deg);
	}
}

.goodsWrapper {
	width: 100%;
	margin-bottom: 25px;
	overflow-x: auto;
}

.goodsWrapper:last-child {
	margin-bottom: 130px;
}

.editTable {
	min-width: max-content;
	overflow-y: hidden;
	border: 1px solid $color-gray-light;
	border-radius: 5px;
}

.editThead {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: $color-gray-table;

	.editTh {
		display: flex;
		flex-basis: 100px;
		flex-grow: 1;
		align-items: center;
		justify-content: center;
		padding: 20px 16px;
		color: $color-gray-dark;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
	}

	.editTh.hint {
		display: flex;
		justify-content: space-between;
		text-align: center;
	}
}

.editTr {
	display: flex;
	justify-content: space-between;
}

.editThValue {
	display: flex;
	flex-basis: 110px;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	color: $color-gray-darker;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
}

.editTd {
	padding: 8px 8px 8px 16px;
	font-size: 12px;
	text-align: center;
	box-shadow: -1px -1px 0 0 #e4e4ef inset;
}

.checkGood {
	align-self: center;
	max-width: 60px;
	padding: 20px 16px;

	[class*='checkmark'] {
		background: #e7e7eb;
		border: none;
	}
}

.goodName {
	min-width: 300px;
}

.active {
	background: #8dc6401a;
	box-shadow: inset 0 -1px 0 rgb(141 198 64 / 25%), inset 0 1px 0 rgb(141 198 64 / 25%);
}
