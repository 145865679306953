@import 'src/assets/styles/mixins';

.box {
	&__result {
		display: flex;
		padding: 0 16px;

		&_item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: max-content;
			margin-right: 5px;
			padding: 6px;
			font-weight: 500;
			font-size: 9px;
			line-height: 12px;
			background: $color-white;
			border-radius: 3px;
		}

		&_text {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&_title {
			margin: 0 10px 0 7px;
			color: $color-primary-dark;
			font-weight: 500;
			font-size: 9px;
			line-height: 12px;
		}

		&_close {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
		}
	}
}
