@import 'src/assets/styles/mixins';

.orderItem {
	&_td {
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		padding: 15px 6px;
		font-weight: 500;
		font-size: 11px;
		text-align: center;
		text-overflow: ellipsis;
		border-right: 1px solid $color-border-gray;
		transition: width 0.1s ease-in-out;

		&:first-child {
			border-left: 1px solid $color-border-gray;
		}

		&__id {
			color: $color-green-dark;
		}
	}
}
