@import 'assets/styles/mixins';

.adminSession {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 20px;
	color: $color-white;
	font-size: 12px;
	line-height: 16px;
	background-color: $color-green;
	visibility: visible;
	opacity: 1;

	&__attention,
	&__action {
		display: flex;
		align-items: center;
		justify-content: start;
		height: 32px;
	}

	&__action {
		padding-left: 20px;
		background: #5e882599;
		cursor: pointer;

		&:hover {
			background-color: $color-green-dark;
		}
	}

	&__close {
		margin: 0 0 0 10px;
		padding-right: 20px;
	}

	&__text {
		margin: 0 0 0 10px;
	}

	&__hidden {
		height: 0;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s, visibility 0.5s, height 3s;

		[class*='__close'] {
			display: none;
		}
	}

	@include respond-below(xs) {
		padding: 0;

		&__attention svg,
		&__little {
			display: none;
		}

		&__text {
			margin-left: 0;
		}

		&__close {
			padding-right: 0;
		}

		&__action {
			padding: 8px 12px;
			text-align: center;
			border-radius: 3px;
		}
	}
}
