@import 'assets/styles/mixins';

.report {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding: 64px 0;
	background-color: $color-service-grey;
	border-radius: 16px;
	cursor: pointer;
	transition: background-color 0.5s ease-in-out;

	&:not(:last-child) {
		margin-right: 10px;
	}

	&:hover {
		background-color: $color-green-opacity;

		.title {
			color: $color-green-dark;
		}

		[class*='button'] {
			background-color: $color-green;
		}
	}

	&__error {
		background-color: $color-red-opacity;

		&:hover {
			background-color: $color-red-opacity;
		}
	}

	&__disabled {
		color: $color-typography-tertiary;
		cursor: default;

		.title {
			color: $color-tertiary;
		}

		&:hover {
			background-color: $color-service-grey;

			.title {
				color: $color-tertiary;
			}

			[class*='button'][class*='disabled'] {
				background-color: $color-white;
			}
		}
	}

	&__blocked {
		background-color: $color-red-opacity;

		.title {
			color: $color-tertiary;
		}

		[class*='button'][class*='disabled'] {
			color: $color-tertiary;
		}

		&:hover {
			background-color: $color-red-opacity;

			.title {
				color: $color-tertiary;
			}

			[class*='button'][class*='disabled'] {
				background-color: $color-white;
			}
		}
	}
}

.name {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 48px;
}

.title {
	padding-bottom: 8px;
	color: $color-typography-primary;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	transition: color 0.5s ease-in-out;
}

.description {
	color: #7b8290;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.action {
	margin-top: 32px;
	margin-bottom: 32px;
}

.error {
	width: max-content;
	padding: 24px 12px;
	color: $color-red;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	background-color: $color-red-opacity;
	border-radius: 8px;

	&:hover {
		background-color: $color-red-opacity !important;
	}
}

.dates {
	flex-grow: 1;
	color: $color-typography-tertiary;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-align: left;

	&_generate {
		margin-top: -16px;
	}
}
