@import 'assets/styles/mixins';

.lang {
	&_choice {
		padding: 16px 15px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}

	&_lang {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 16px 15px;
		border-top: 1px solid $color-service-grey;
		cursor: pointer;

		&:hover {
			background-color: $color-grayer;
		}
	}

	&_text {
		padding-right: 15px;
		color: $color-black;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}
