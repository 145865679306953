@import 'src/assets/styles/mixins';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 7000;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: #0000000f;

	&_body {
		position: relative;
		width: 420px;
		background: $color-white;
		border-radius: 12px;
		box-shadow: 0 15px 30px 0 #00000014;
	}

	&_title {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
	}

	&_close {
		position: relative;
		z-index: 1;
		margin-bottom: -24px;
		padding: 16px 16px 0 0;
		text-align: right;
		cursor: pointer;

		&:hover {
			svg > path {
				fill: $color-gray-dark;
			}
		}

		&__message {
			padding: 19px 19px 0 0;

			&:hover {
				svg > path {
					fill: $color-green-dark;
				}
			}
		}
	}
}

.separate {
	height: 1px;
	padding-bottom: 0;
	transition: padding-bottom 0.4s ease-in-out;

	&_message {
		padding-bottom: 15px;
	}
}
