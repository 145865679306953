@import 'src/assets/styles/mixins';

.error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100% - 118px);

	&_image {
		display: flex;
		justify-content: center;
	}

	&_actions {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 50px;
	}

	&_text {
		display: flex;
		align-items: center;
		color: $color-black-dark;
		font-size: 16px;
	}

	&_link {
		margin-left: 16px;
		padding: 8px 16px;
		color: $color-white;
		font-size: 16px;
		line-height: 24px;
		background: $color-green;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			background: $color-green-text;
		}
	}
}

@include respond-below(xs) {
	.error {
		&_image img {
			width: 81%;
			height: auto;
		}
	}
}

@include respond-below(xxs) {
	.error {
		&_text {
			justify-content: center;
			width: 100%;
			margin-bottom: 10px;
		}
	}
}
