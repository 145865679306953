@import 'src/assets/styles/vars';

$breakpoints: (
	xxs: 375px,
	xs: 600px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1400px,
	xxl: 1800px,
);
$max-width: 1280;
$min-width: 320;

@mixin respond-above($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (min-width: $breakpoint-value) {
			@content;
		}
	} @else {
		@warn "Invalid breakpoint: #{$breakpoint}.";
	}
}

@mixin respond-below($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}
	} @else {
		@warn "Invalid breakpoint: #{$breakpoint}.";
	}
}

@mixin respond-between($lower, $upper) {
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);

		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}
	} @else {
		@if map-has-key($breakpoints, $lower) == false {
			@warn "Your lower breakpoint was invalid: #{$lower}.";
		}

		@if map-has-key($breakpoints, $upper) == false {
			@warn "Your upper breakpoint was invalid: #{$upper}.";
		}
	}
}

@mixin adaptive($property, $start-value, $end-value, $mode) {
	$add-value: $start-value - $end-value;

	@if $mode == 0 {
		#{$property}: $start-value + px;

		@media (max-width: #{$max-width + px}) {
			#{$property}: calc(
				#{$end-value + px} + #{$add-value} * ((100vw - #{$min-width + px}) / #{$max-width - $min-width})
			);
		}
	}

	@if $mode == 1 {
		#{$property}-top: $start-value + px;
		#{$property}-bottom: $start-value + px;

		@media (max-width: #{$max-width + px}) {
			#{$property}-top: calc(
				#{$end-value + px} + #{$add-value} * ((100vw - #{$min-width + px}) / #{$max-width - $min-width})
			);
			#{$property}-bottom: calc(
				#{$end-value + px} + #{$add-value} * ((100vw - #{$min-width + px}) / #{$max-width - $min-width})
			);
		}
	}

	@if $mode == 2 {
		#{$property}-left: $start-value + px;
		#{$property}-right: $start-value + px;

		@media (max-width: #{$max-width + px}) {
			#{$property}-left: calc(
				#{$end-value + px} + #{$add-value} * ((100vw - #{$min-width + px}) / #{$max-width - $min-width})
			);
			#{$property}-right: calc(
				#{$end-value + px} + #{$add-value} * ((100vw - #{$min-width + px}) / #{$max-width - $min-width})
			);
		}
	}
}

@mixin auth-center {
	margin: 0 auto;
}

@mixin auth-header {
	display: block;
	margin: 0 auto;
	color: $color-gray-dark;
	font-weight: 500;
	text-align: center;

	@include adaptive('font-size', 36, 20, 0);
	@include adaptive('margin-bottom', 60, 40, 0);
}

@mixin header {
	.header {
		margin: 16px 0 0;
		padding: 0 0 6px;
		color: $color-black;
		font-weight: bold;
		font-size: 24px;

		@include respond-above(xs) {
			display: none;
		}
	}
}

@mixin content {
	padding: 16px;

	@include respond-above(xs) {
		min-height: calc(100vh + 130px);
		padding: 0;
	}

	@include respond-below(xs) {
		min-height: 120vh;
	}
}
