@import 'src/assets/styles/mixins';

.orders {
	@include content;

	&__grouping {
		min-height: unset;
	}
}

.header {
	margin: 16px 0 0;
	padding: 0 0 6px;
	color: $color-black;
	font-weight: bold;
	font-size: 24px;

	@include respond-above(xs) {
		display: none;
	}

	@include respond-below(xs) {
		font-size: 20px;
	}
}

@include respond-above(xs) {
	.visible {
		display: block;
		margin: 0;
		padding: 24px 0 0 20px;
		color: $color-black;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		background: $color-white;
		border-top: 1px solid $color-gray-light;
	}
}
