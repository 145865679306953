@import 'assets/styles/mixins';

.modes {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 145px;
	border-radius: 10px;

	&_month,
	&_year {
		flex: 1;
		padding: 4px;
		color: $color-primary-dark;
		font-size: 12px;
		text-align: center;
		background-color: $color-white;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
	}

	&_active {
		color: $color-white;
		background-color: $color-base-grey;
	}
}
