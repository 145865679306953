@import 'assets/styles/mixins';

.homeBanners {
	width: 32%;
}

@include respond-below(xxl) {
	.homeBanners {
		width: 48%;
		margin-bottom: 20px;
	}
}

@include respond-below(sm) {
	.homeBanners {
		width: 100%;
	}
}
