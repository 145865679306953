@import 'src/assets/styles/global';

.table {
	&_heading {
		display: flex;
		justify-content: center;
		background: $color-gray-group-heading;
		box-shadow: inset -1px -1px 0 0 #e4e4ef;
	}

	.tr {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 85%;
		box-shadow: 0 -1px 0 0 #e4e4ee inset;
		transition: all 0.3s ease-in-out;

		&__sticky {
			color: $color-gray-group-heading;
		}
	}

	.th {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: space-between;
		width: max-content;
		height: 100%;
		padding: 8px;
		color: $color-gray-dark;
		font-weight: 600;
		font-size: 10px;
		line-height: 16px;
		text-align: left;
		box-shadow: inset -1px -1px 0 0 #e4e4ef;
		transition: all 0.9s ease-in-out;

		&_warehouse {
			flex-basis: 13%;
			justify-content: center;
		}

		&_checkx {
			flex-basis: 5%;
		}
	}

	.drag {
		cursor: grab;

		&:hover {
			background: $color-gray-lightest;
		}
	}

	.warehouse {
		flex-basis: 200px;
	}

	&_sticky {
		position: sticky;
		z-index: 500;
		color: $color-gray-light;
		visibility: visible;
		opacity: 1;
		transition: top 0.7s;
	}
}

.selected {
	opacity: 0.5;
}

.checkmark {
	margin-left: -4px;
}

.check {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 55px;
	height: 100%;
	padding: 8px 0;
	border-right: 1px solid $color-border-gray;
	border-left: 1px solid $color-border-gray;
}
