@import 'src/assets/styles/mixins';

@keyframes confirm {
	0% {
		color: $color-gray-darkest;
	}

	50% {
		color: $color-black-dark;
	}

	100% {
		color: $color-gray-darkest;
	}
}

.table {
	width: 100%;
	margin-bottom: 180px;
	border-collapse: collapse;
}

.th {
	padding: 8px 8px 8px 16px;
	color: $color-gray-dark;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	box-shadow: -1px -1px 0 0 #e4e4ef inset;

	&__dark {
		[class*='checkmark'] {
			background-color: $color-gray-dark;
		}
	}

	&__reports {
		text-align: left;
	}

	&__drag {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.td {
	padding: 8px 8px 8px 16px;
	font-size: 12px;
	text-align: center;
	box-shadow: -1px -1px 0 0 #e4e4ef inset;

	&_confirm {
		animation: confirm 1.5s infinite;
	}

	&__tabak {
		background: $color-green-opacity;
	}

	&__alcohol {
		background: $color-orange-opacity;
	}

	&__default {
		background: $color-gray-lightest;
	}

	&__reports {
		text-align: left;
	}

	&__planGoodsId {
		color: $color-dark-green;
	}
}

.id {
	color: $color-green-dark;
	font-weight: 500;
	cursor: pointer;
	transition: text-decoration 0.8s cubic-bezier(0.4, 0, 0.2, 1), color 0.5s ease;

	&:hover {
		color: $color-green;
		text-decoration: underline;
	}
}

.warehouse {
	width: 350px;
	text-align: center;
}

.status {
	width: 220px;
}

thead {
	.tr,
	.tr:nth-child(even) {
		background-color: $color-gray-table;

		&:hover {
			background-color: $color-gray-table;
		}
	}

	.tr__sticky {
		position: sticky;
		z-index: 500;
		height: 56px;
		color: $color-gray-light;
		background-color: $color-sticky-head;
		visibility: visible;
		opacity: 1;
		transition: top 0.7s;

		.th {
			font-weight: 500;
			box-shadow: none;
		}

		&:hover {
			color: $color-gray-light;
			background-color: $color-sticky-head;
		}
	}

	.tr__fast {
		visibility: hidden;
		opacity: 0;
	}

	.tr__hidden {
		visibility: hidden;
		opacity: 0;
	}
}

.goods {
	min-width: 100px;
	padding: 8px 4px 4px 16px;
}

.reports {
	width: 60px;
}

.hidden {
	display: none;
}

.tr.active {
	background: $color-green-opacity;

	&:hover {
		background-color: $color-green-opacity;
	}
}
