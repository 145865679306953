@import 'src/assets/styles/mixins';

.button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 68px;
	font-weight: 600;
	font-size: 14px;

	&_current {
		border-bottom: 1px solid $color-green-dark;
	}

	&_text {
		&__opened {
			color: $color-green-dark;
		}
	}

	&_arrow {
		display: flex;
		align-self: center;

		svg > path {
			fill: $color-gray-text;
		}
	}
}
