@import 'src/assets/styles/mixins';

.desktop {
	display: flex;
	justify-content: space-between;

	&__overflow {
		overflow-x: auto;
	}
}

.order {
	display: block;
}
