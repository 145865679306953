@import 'src/assets/styles/mixins';

.button {
	width: 36px;
	border: 1px solid $color-service-grey;
	border-radius: 8px;
	transition: border 0.3s ease, box-shadow 0.3s ease;

	&:hover {
		border: 1px solid $color-green;
		box-shadow: $shadow-button;

		svg > path {
			fill: $color-green;
		}
	}

	&__opened {
		background-color: $color-green;
		border: 1px solid $color-green;

		&:hover {
			svg > path {
				fill: $color-white;
			}
		}
	}
}

@include respond-below(xs) {
	.button {
		width: max-content;
		padding: 0;
		border: none;
		transition: none;

		svg > path {
			fill: $color-gray;
		}

		&:hover {
			border: none;
			box-shadow: none;

			svg > path {
				fill: $color-gray;
			}
		}

		&__opened,
		&__opened:hover {
			background: transparent;
			border: none;
			box-shadow: none;

			svg > path {
				fill: $color-green;
			}
		}
	}
}
