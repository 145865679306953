@import 'assets/styles/global';

.mass {
	margin-left: 10px;

	&_opened {
		min-width: 175px;
		background: $color-green;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
}
