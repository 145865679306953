@import 'src/assets/styles/mixins';

.filter {
	padding: 16px;
}

.item {
	margin-bottom: 12px;
	font-weight: 500;
	font-size: 12px;

	&_label {
		display: block;
		margin-bottom: 8px;
		color: $color-base-grey;
	}

	&_line {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		[class*='ant-picker-focused'] {
			background: $color-white;
			border: 1px solid $color-green;
			box-shadow: none;
		}

		[class*='ant-picker']:not([class*='ant-picker-input'], [class*='ant-picker-focused']):hover {
			border: 1px solid $color-service-grey;
		}

		&__item {
			width: 48%;
			height: 36px;
			background: $color-service-grey;
			border: 1px solid $color-service-grey;
			border-radius: 3px;

			[class*='ant-picker-dropdown'] {
				z-index: 1050000 !important;
			}

			[class*='ant-picker-input'] input {
				top: 1px;
				margin-left: 30px;
				font-size: 12px;
			}

			&:first-child::before,
			&:last-child::before {
				position: absolute;
				left: 12px;
				display: block;
				color: #c3cad3;
				font-size: 12px;
			}

			&:first-child::before {
				content: attr(title);
			}

			&:last-child::before {
				content: attr(title);
			}
		}
	}
}
