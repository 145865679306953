@import 'src/assets/styles/mixins';

.update {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;

	[class*='ant-picker-input'] input {
		padding-top: 3px;
		font-size: 12px;
	}
}

.order {
	justify-content: end;
}

.date {
	display: flex;
	align-self: center;
	padding-top: 3px;
}

.edit {
	display: flex;
	align-self: center;
	margin-left: 10px;
	cursor: pointer;
	transition: all 1s;

	&:hover {
		svg > path {
			fill: $color-green-dark;
		}
	}
}

@include respond-below(xs) {
	.update {
		justify-content: end;
	}
}
