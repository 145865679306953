@import 'src/assets/styles/mixins';

.header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 16px 20px;
	background-color: $color-white;
	border-bottom: 1px solid $color-gray-light;
}

.title {
	color: $color-black-dark;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
}

.count {
	padding-left: 10px;
	color: $color-green;
}

.list {
	display: flex;
	flex-flow: column;
	height: calc(100vh - 120px);
	overflow-y: auto;
	background-color: $color-white;
	scrollbar-width: thin;
	scrollbar-color: $scroll-color $scroll-field-color;

	&__item {
		display: block;
		padding: 20px;
		color: $color-gray-text;
		font-size: 12px;
		border-bottom: 1px solid $color-gray-light;
		cursor: pointer;

		&:hover {
			background-color: $color-grayer;
		}
	}

	&::-webkit-scrollbar {
		background: $color-white;
	}

	&::-webkit-scrollbar:double-button {
		color: $scroll-color;
	}

	&::-webkit-scrollbar-track {
		background: $color-white;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scroll-color;
		border: 6px solid $color-white;
		border-radius: 50px;
	}
}

.event {
	padding: 16px;
	border-bottom: 1px solid $color-gray-light;

	&_actions {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	&_date_block {
		display: flex;
		justify-content: start;
		margin-bottom: 10px;
	}

	&_date {
		color: $color-text-name;
		font-size: 10px;
	}

	&_type {
		margin-right: 6px;
	}

	&_analytics {
		margin-bottom: 16px;
	}

	&_type.success svg > path {
		fill: $color-green;
	}

	&_type.warning svg > path {
		fill: $color-orange;
	}

	&_type.error svg > path {
		fill: $color-red;
	}

	&_title {
		margin-bottom: 6px;
		color: $color-gray-text;
		font-weight: bold;

		a {
			color: $color-green;
			text-decoration: underline;
		}
	}

	&_description {
		color: $color-text-name;
	}

	&_title,
	&_description {
		font-size: 10px;
		line-height: 12.1px;
	}

	&_close svg {
		width: 8px;
		height: 8px;
		cursor: pointer;
		transition: transform 0.5s ease;

		&_disabled {
			cursor: default;
		}

		&:hover {
			transform: scale(1.5);
		}
	}

	&_close svg > path {
		fill: #c9c9d8;
	}

	&__hidden {
		display: none;
	}

	&_more {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 20px 0 30px;
		color: $color-gray-lightest;
		font-weight: 700;
		font-size: 12px;
		cursor: pointer;

		&:hover {
			color: $color-gray-dark;
		}

		&__hidden {
			display: none;
		}
	}
}

@include respond-below(xs) {
	.event {
		padding-right: 8px;
	}
}
