@import 'src/assets/styles/mixins';

.month {
	width: 20%;
	cursor: pointer;
	transition: width 0.3s ease-in-out;

	&_title {
		color: $color-base-grey;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		text-align: left;
	}

	&_yearDaysName {
		display: flex;
		justify-content: space-around;
		padding: 18px 0 10px;
		border-bottom: 1px solid $color-gray-light;

		&__item {
			color: $color-middle-gray;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
		}
	}

	@include respond-below(md) {
		width: 23%;

		&_title {
			font-size: 13px;
		}

		&_yearDaysName {
			&__item {
				font-size: 13px;
			}
		}
	}
}

.week {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;

	&_day {
		display: flex;
		flex: 1;
		justify-content: center;
		transition: opacity 0.3s ease-in-out;

		&__downTime {
			color: $color-white;
			background-color: #d9464699;
		}

		&__today {
			color: $color-primary-dark;
			background-color: #8dc64040;
			border-radius: 4px;
		}

		&__past {
			color: $color-middle-gray;
			background-color: transparent;
		}

		&__borderLeft {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

		&__borderRight {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
}
