@import 'src/assets/styles/mixins';

.toggle {
	position: relative;
	display: flex;
	align-items: center;
	width: 28px;
	height: 16px;
	border-radius: 50px;
	cursor: pointer;

	&_on {
		background: $color-green;
		border: 1px solid $color-green;
	}

	&_off {
		background: $color-gray;
		border: 1px solid $color-gray;
	}

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 28px;
		margin: 0;
		cursor: pointer;
		opacity: 0;
	}
}

.button {
	position: absolute;
	width: 14px;
	height: 14px;
	background-color: $color-white;
	border-radius: 50%;

	&_on {
		right: 0;
	}

	&_off {
		left: 0;
	}
}
