@import 'assets/styles/mixins';

.policy {
	padding: 20px 20px 200px;

	&_header {
		padding-bottom: 12px;
		color: $color-black;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		text-transform: uppercase;
	}

	&_content {
		width: 70%;
		text-align: justify;
	}

	&_center {
		text-align: center;
	}

	&_table {
		width: 100%;
		overflow: auto;

		table,
		th,
		td {
			padding: 0 6px;
			vertical-align: top;
			border: 1px solid $color-base-grey;
			border-collapse: collapse;
		}
	}

	a {
		color: $color-green;
		text-decoration: underline;
		cursor: pointer;
		transition: color 0.3s ease-in-out;

		&:hover {
			color: $color-dark-green;
		}
	}

	&_list li {
		list-style: square;
	}

	&_right {
		max-width: 300px;
		margin: 0 0 0 auto;
	}
}

@include respond-below(md) {
	.policy {
		padding: 15px;

		&_content {
			width: 100%;
		}
	}
}
