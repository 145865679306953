@import 'src/assets/styles/mixins';

.settings {
	margin-right: 10px;
}

@include respond-below(xs) {
	.settings {
		margin-right: 6px;
	}
}
