@import 'src/assets/styles/mixins';

.arrow {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 8px;
	cursor: pointer;

	svg > path {
		transition: fill 0.5s ease-in-out;
	}

	&:hover svg > path {
		fill: #212122;
	}

	&__right {
		svg {
			transform: rotate(180deg);
		}
	}

	&__disabled:hover {
		cursor: unset;

		svg > path {
			fill: $color-gray;
		}
	}
}
