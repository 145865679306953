@import 'assets/styles/mixins';

.panel {
	display: flex;
	width: 190px;
	padding: 2px;
	background-color: $color-service-grey;
	border-radius: 8px;

	&_type {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		height: 32px;
		box-shadow: 0 0 4px 0 #0000000f;
		cursor: pointer;
		transition: background-color 0.1s ease-in-out;
	}

	&_active {
		background-color: $color-white;
		border-radius: 6px;

		svg > path,
		svg > rect {
			stroke: $color-green;
		}
	}

	&_text {
		margin-left: 8px;
		color: $color-primary-dark;
		font-weight: 600;
		font-size: 11px;
		line-height: 16px;
	}
}
