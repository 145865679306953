@import 'src/assets/styles/mixins';

.wrapper:nth-child(odd) {
	background-color: $color-gray-table;
}

.property {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 670px;
	max-width: 100%;
	padding: 0 20px;
	color: $color-black-dark;
	font-size: 12px;
	line-height: 26px;

	&__name {
		font-weight: 600;
	}

	&__value {
		display: flex;
		font-weight: 400;
	}
}

.sign {
	min-width: 26px;
	height: auto;
}

@include respond-below(xs) {
	.wrapper {
		margin-right: -16px;
		margin-left: -16px;
		padding: 0 16px;
		background: $color-white;

		&:nth-child(odd) {
			background: inherit;
		}

		&:not(:last-child) .property {
			box-shadow: $shadow-bottom;
		}
	}

	.property {
		width: 100%;
		padding: 10px 0;
		color: $color-black;

		&__name {
			width: 45%;
			color: $color-text-name;
			font-weight: 400;
			line-height: 20px;
		}

		&__value {
			font-weight: 600;
			line-height: 20px;
			text-align: right;
		}
	}
}
