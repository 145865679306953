@import 'src/assets/styles/mixins';

.left {
	display: flex;
	transition: all 0.3s ease-in-out;

	&_hidden {
		overflow: hidden;
	}
}

@include respond-below(xs) {
	.left {
		flex-basis: 200px;
		padding-left: 0;
		color: $color-gray-dark;
		font-weight: normal;
		font-size: 12px;

		div {
			padding-left: 0;
		}
	}
}

@include respond-above(xs) {
	.left {
		position: relative;
		z-index: 10;
		width: 40%;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 16px;
		background: $color-white;

		&_events {
			background: $color-grayer;
		}
	}
}

@include respond-below(sm) {
	.left {
		position: relative;
		z-index: 10;
		width: 60%;
	}
}
