@import 'assets/styles/global';

.item {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 10px 16px;

	&__help {
		position: absolute;
		top: 0;
		width: max-content;
		padding: 10px 8px;
		color: $color-white;
		background: #2b2e3499;
		box-shadow: 0 16px 32px 0 #0000001a;
	}

	&__withHelp::after {
		position: absolute;
		top: 10px;
		right: 0;
		z-index: 100000 !important;
		border: 6px solid transparent;
		border-right: 6px solid #2b2e3499;
		opacity: 1;
		content: '';
	}

	&__withoutHelp::after {
		opacity: 0;
	}

	&:hover {
		background: $color-service-grey;
	}

	&:last-child {
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}
}
