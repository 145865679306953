@import 'assets/styles/global';

.promo {
	margin-bottom: 10px;
	padding: 20px;
	background: $color-white;
	border-radius: 12px;
	box-shadow: 0 2px 4px 0 #0000001a;
	cursor: pointer;

	&:hover {
		box-shadow: 0 6px 30px 0 #00000029;
		transition: box-shadow 0.5s ease;
	}

	&:hover &_sku,
	&:hover &_title,
	&:hover &_date {
		color: $color-green;
		transition: color 0.5s ease;
	}

	&:hover svg > path {
		transition: fill 0.5s ease;
		fill: #8dc640;
	}

	&_image {
		display: flex;
		align-items: center;
	}

	&_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&_left {
		display: flex;
		align-items: center;
	}

	&_sku {
		padding: 0 6px 0 16px;
		color: $color-primary-dark;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;

		&__promo {
			border-right: 1px solid $color-gray-light;
		}
	}

	&_title {
		padding: 0 6px;
		color: $color-primary-dark;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	&_right {
		display: flex;
		place-content: center space-between;
	}

	&_date {
		color: $color-base-grey;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
	}

	&_arrow {
		display: flex;
		align-self: center;
		margin-left: 24px;

		&__top svg {
			transform: rotate(180deg);
			transition: all 0.5s ease;
		}
	}

	&_info {
		&__name,
		&__mobile {
			display: none;
		}

		&__info {
			color: $color-grayer-text;
			font-size: 12px;
			line-height: 16px;
		}
	}

	&_card {
		margin: 20px 0 0 35px;
		padding-top: 20px;
		border-top: 1px solid $color-gray-light;
		transition: opacity 0.3s ease;

		&__sku {
			margin-bottom: 8px;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
		}

		&__title {
			margin-bottom: 20px;
			font-weight: 400;
			font-size: 14px;
		}

		&__info {
			display: none;
		}
	}

	&_property {
		display: flex;
		margin-bottom: 12px;
		place-content: center start;
	}

	&_name {
		width: 180px;
		color: $color-base-grey;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	&_value {
		color: $color-primary-dark;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	&_mobile {
		display: none;

		&__date {
			display: none;
		}
	}
}

@include respond-below(sm) {
	.promo {
		&_top {
			align-items: start;
		}

		&_date,
		&_sku,
		&_title {
			display: none;
		}

		&_arrow {
			width: 16px;
			height: 16px;
			margin-top: 5px;
			margin-left: 10px;
		}

		&_card {
			margin: 0;
			padding-top: 16px;
			border-top: none;

			&__sku,
			&__title {
				display: none;
			}

			&__event {
				padding-top: 0;
			}

			&__info {
				display: block;
				margin-bottom: 16px;
				color: $color-grayer-text;
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
			}
		}

		&_info {
			&__name {
				display: block;
				padding: 8px 0;
				color: $color-grayer-text;
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				border-bottom: 1px solid #8dc6404d;
			}

			&__mobile {
				display: block;
				border-bottom: none;
			}

			&__info {
				padding: 8px 0;
				color: $color-grayer-text;
				font-size: 12px;
				line-height: 16px;
			}
		}

		&_properties {
			padding-bottom: 8px;
		}

		&_property {
			display: block;
			margin-bottom: 8px;
		}

		&_name {
			margin-bottom: 4px;
			color: $color-base-grey;
			font-size: 10px;
		}

		&_value {
			font-size: 12px;
		}

		&_mobile {
			display: block;
			margin-left: 8px;
			color: $color-black;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;

			&__active {
				color: $color-dark-green;
			}

			&__date {
				display: block;
				margin-top: 8px;
				color: $color-gray-bebe;
				font-weight: 500;
				font-size: 10px;
				line-height: 12px;
			}
		}
	}
}
