@import 'src/assets/styles/mixins';

.open {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;
	font-size: 12px;

	&_button {
		width: 50%;

		span {
			display: inline-block;
			margin-right: 6px;
		}
	}

	&_details {
		color: $color-green;
	}
}

.arrow {
	&__transform {
		transform: rotate(180deg);
	}
}
