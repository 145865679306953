@import 'src/assets/styles/mixins';

.counter {
	display: flex;
	flex-direction: column;
	width: 20%;
	color: $color-white;

	&__increase,
	&__decrease {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 21px;
		font-size: 12px;
		background: $color-green;
		border: 1px solid $color-green;
		cursor: pointer;

		&:hover {
			background: $color-green-text;
			border: 1px solid $color-green-text;
		}
	}

	&__disabled {
		background: $color-gray-dark;
		border: 1px solid $color-gray-dark;
		cursor: default;

		&:hover {
			background: $color-gray-dark;
			border: 1px solid $color-gray-dark;
		}
	}

	&__mobile {
		height: 100%;
	}

	&__endValue {
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: center;
		width: 25%;
		padding: 11px;
		color: #8a9099;

		@include respond-below(xs) {
			min-width: 57px;
			font-weight: normal;
			font-size: 12px;
		}
	}

	&__arrow {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #8a9099;
	}
}
