@import 'src/assets/styles/mixins';

.icons {
	display: flex;
	justify-content: space-between;
}

.item {
	display: flex;
	align-self: center;
	margin-left: 20px;
	cursor: pointer;
}

.submenu {
	display: flex;
	justify-content: flex-start;
	padding: 30px 0 15px;

	&__unAuth {
		justify-content: flex-end;
		padding-right: 20px;
	}

	&__list {
		background-color: $color-white;
	}
}

.tutorial {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 20px 20px;
}

.active > svg > path,
.active [class*='events'] > svg > path {
	fill: $color-green;
}

@include respond-below(md) {
	.icons {
		display: none;
	}
}
