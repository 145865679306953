@import 'src/assets/styles/mixins';

.toggle {
	display: flex;
	justify-content: space-between;
}

.passive {
	color: $color-gray-dark;
}

.text {
	width: 90%;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.checkbox {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	[class*='inputCheckbox_checkmark'] {
		margin-left: -8px;
		border: 1px solid #e4e4ef;
	}

	[class*='inputCheckbox_input']:hover {
		background: $color-service-grey;
	}

	&:hover [class*='inputCheckbox_checkmark'] {
		background: $color-service-grey;
	}
}
