@import 'src/assets/styles/mixins';

.reports {
	padding: 32px 0;

	&_table {
		display: block;
		min-height: 100vh;

		&__noResult {
			display: flex;
			place-content: center center;
			min-height: 50vh;
		}
	}

	&_header {
		margin-bottom: 32px;
		padding: 0 20px;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
	}

	&_sub {
		color: $color-text-name;
	}

	&_title {
		color: $color-black;
	}

	&_actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
		padding: 0 20px;

		&__left {
			display: flex;
			justify-content: flex-start;
		}
	}

	@include respond-below(sm) {
		display: none;
	}
}
