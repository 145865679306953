@import 'src/assets/styles/mixins';

.event {
	width: 200px;
	padding: 8px 12px;
	color: $color-primary-dark;
	border: 1px solid $color-grayer;
	border-radius: 8px;
	cursor: pointer;
	transition: border 0.3s ease, box-shadow 0.3s ease;

	&_downtimes {
		&__active {
			svg path {
				stroke: $color-green;
			}
		}
	}
}

.active {
	color: $color-black;
	background: $color-white;
	border: 1px solid $color-service-grey;

	svg > path {
		fill: $color-green;
	}
}

@include respond-below(md) {
	.event {
		width: max-content;

		svg {
			display: none;
		}
	}
}

@include respond-below(xs) {
	.event {
		width: 50%;
		margin: 0;
		color: $color-gray-dark;
		font-size: 10px;
		text-align: center;
		border: none;

		&:first-child {
			border-right: 1px solid $color-green;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.active {
		color: $color-white;
		background-color: $color-green;
		border: 1px solid $color-green;
		border-radius: 0;
	}
}

@include respond-below(xxs) {
	.event {
		width: 100%;

		&:first-child {
			border-right: 0;
			border-bottom: 1px solid $color-green;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}
