$color-black: #303030;
$color-black-dark: #000;
$color-brand-secondary: #373c46;
$color-typography-primary: #202122;
$color-typography-secondary: #7b8290;
$color-typography-tertiary: #9ea4af;
$color-gray-placeholder: #b8bcc2;
$color-gray: #d9d9d9;
$color-primary-dark: #2b2e34;
$color-primary-dark-opacity: #e4e4ef40;
$color-base-grey: #8a9099;
$color-gray-light: #e4e4ef;
$color-gray-lightest: #eceef0;
$color-gray-table: #e4e4ef40;
$color-gray-grouping: #d0d0ddd6;
$color-gray-dark: #8a9099;
$color-cookies: #4b4e53;
$color-gray-text: #606060;
$color-grayer-text: #666;
$color-middle-gray: #d0d0dd;
$color-sticky-head: #212121db;
$color-bread: #ffffff14;
$color-grayer: #f8f8fb;
$color-check-group: #d8d8e2;
$color-gray-more: #c3cad3;
$color-gray-darker: #1a1a1a;
$color-primary-dark: #2b2e34;
$color-border-group: #f9f9fc;
$color-border-gray: #dfdff0;
$color-text-name: #909090;
$color-gray-bebe: #bebebe;
$color-files-gray: #eeeef4;
$color-files-result: #ababb9;
$color-white: #fff;
$color-green-dark: #5e8825;
$color-green-opacity: #8dc64033;
$color-dark-green: #5e8825;
$color-green-white: #e2f0cf;
$color-green-text: #5e8825;
$color-green: #8dc640;
$color-light-green: #afde70;
$color-schedule-tooltip-quantity: #c5fb7d;
$color-light-opacity-green: #c5fb7d80;
$color-light-hover-green: #c5fb7dd5;
$color-light-opacity-blue: #3a8ee633;
$color-light-hover-blue: #3a8ee666;
$color-schedule-week-border: #6faae6;
$color-opacity-green: #8dc64040;
$color-brand-green: #8dc640;
$color-red: #ec0522;
$color-red-danger: #d94646;
$color-red-danger-opacity: #d9464699;
$color-redder: #ec0522ff;
$color-red-blur: #d94646;
$color-red-opacity: #d9464626;
$color-gray-downtimes: #eff3f599;
$color-orange: #dfb241;
$color-orange-typography: #fcc93429;
$color-orange-downtimes: #ffedca;
$color-orange-opacity: #f6efdf;
$color-blue: #1757b6;
$color-gray-darkest: #373c46;
$scroll-field-color: #2c3038;
$color-tertiary: #9ea4af;
$scroll-color: #747880;
$color-service-grey: #eff3f5;
$color-export-icon-gray: #dcdce5;
$color-service-orange: #fef6e6;
$shadow-button: 0 0 0 2px #8dc64040;
$block-shadow: 0 4px 12px rgb(0 0 0 / 16%);
$shadow: 0 2px 8px 0 #0000001a;
$shadow-menu: -6px 0 30px 0 #0003;
$shadow-light-right: -1px 0 0 0 #e4e4ef inset;
$shadow-light-left: 1px 0 0 0 #e4e4ef inset;
$shadow-bottom: 0 -1px 0 0 #f1f1f7 inset;
$scroll-field-color: #2c3038;
$scroll-color: #747880;
$color-service-grey: #eff3f5;
$color-gray-group-heading: #f3f3f8;
