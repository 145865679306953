@import 'src/assets/styles/mixins';

.right {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 70%;
}

@include respond-above(xs) {
	.right {
		margin-right: 16px;
	}
}
