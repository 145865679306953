@import 'src/assets/styles/mixins';

.detail {
	margin: 0 0 108px;
}

.status {
	padding: 0 5px;
	color: $color-green-dark;
	text-transform: uppercase;
	background: $color-green-opacity;
	border: 1px solid $color-green-dark;
	border-radius: 3px;
}

.error {
	color: $color-red;
	background: $color-red-opacity;
	border: 1px solid $color-red;

	@extend .status;
}

.warning {
	color: $color-orange;
	background: $color-orange-opacity;
	border: 1px solid $color-orange;

	@extend .status;
}

.default {
	color: $color-gray-dark;
	background: $color-gray-light;
	border: 1px solid $color-gray-dark;
}

@include respond-above(sm) {
	.detail {
		margin-top: 0;
	}
}

@include respond-below(xs) {
	.detail {
		margin-top: 16px;
		margin-bottom: 6px;
		background: $color-white;
	}

	.status {
		padding: 5px;
	}
}
