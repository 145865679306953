@import 'assets/styles/mixins';

.addAction {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 10px;
	border: 1px solid $color-middle-gray;
	border-radius: 8px;
	cursor: pointer;
	transition: padding 0.3s ease-in-out;

	&_text {
		margin-left: 8px;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		transition: margin-left 0.3s ease-in-out;
	}

	&:hover {
		border: 1px solid $color-light-green;
		box-shadow: 0 0 0 2px #8dc64040;

		svg > path {
			stroke: $color-green-dark;
		}
	}

	@include respond-below(sm) {
		&_text {
			display: none;
		}
	}
}
