@import 'src/assets/styles/mixins';

.goodsCard {
	background: #e1e5ea;
}

.goodsBlock {
	display: flex;
	padding: 0 16px;
}

.goodLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px;
	font-weight: 400;
	font-size: 10px;
	line-height: 20px;
	background: #f8f8fb;
	box-shadow: 0 -1px 0 0 #dfdff0 inset;
}

.active {
	padding-top: 0;
	padding-bottom: 0;
	background: $color-green-white;
}

.goodsTitle {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	padding: 20px 16px;
	color: $color-black;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	background: $color-white;

	.goodsHeader {
		display: inline-block;
		margin-right: 10px;
		background: $color-white;
	}

	.goodsHider {
		display: inline-block;
		margin-right: 10px;
	}

	&_arrow svg {
		transform: rotate(180deg);
	}

	[class*='block'] {
		padding-top: 0;
	}

	[class*='input'] {
		margin-right: 10px;
		margin-left: 0;
	}

	[class*='checkmark'] {
		background: linear-gradient(0deg, #c3cad3, #c3cad3),
			linear-gradient(0deg, rgb(255 255 255 / 50%), rgb(255 255 255 / 50%));
		border: 1px solid #c3cad3;
	}

	input:checked ~ [class*='checkmark'] {
		background: $color-green;
		border: 1px solid $color-green;
	}
}

.darkMode {
	margin-bottom: 0;
	background: #f8f8fb;
}

.titleFooter {
	background: #f8f8fb;
}

.propertyValue {
	min-height: 20px;
	color: $color-black-dark;
	font-weight: 600;
	font-size: 10px;
	line-height: 20px;
}

.propertyTitle {
	display: flex;
	color: $color-text-name;
	font-weight: 400;
	font-size: 10px;
	line-height: 20px;
}

.propertyHeader {
	color: #606060;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
}
