@import 'src/assets/styles/mixins';

.eventsList {
	padding: 20px 16px;
}

@include respond-below(xs) {
	.eventsList {
		padding: 16px 0 8px;
	}
}
