@import 'src/assets/styles/mixins';

.password {
	padding: 16px;
	background: $color-files-gray;
	border-radius: 5px;

	&_title {
		margin-bottom: 16px;
		color: $color-text-name;
		font-size: 12px;
	}

	&_input {
		padding: 16px;
	}
}

.button {
	border-radius: 5px;
}

@include respond-below(xs) {
	.button {
		width: 100%;
	}
}
