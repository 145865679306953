@import 'assets/styles/mixins';

.textarea {
	&_textarea {
		width: 100%;
		padding: 7px 10px;
		background: $color-service-grey;
		border: 1px solid $color-service-grey;
		border-radius: 3px;

		&:focus,
		&:active {
			background: $color-white;
			border: 1px solid $color-green;
			outline: none;
		}
	}
}
