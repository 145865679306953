@import 'src/assets/styles/mixins';

.loader {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	width: 5px;
	height: 5px;
	margin: 5px 0 5px 18px;
	background: $color-gray-dark;
	border-radius: 50%;
	animation: shadow-expand 0.8s linear infinite alternate;
}

@keyframes shadow-expand {
	0% {
		color: $color-gray-lightest;
		box-shadow: 0 0, 0 0;
	}

	100% {
		color: $color-gray-dark;
		box-shadow: -10px 0, 10px 0;
	}
}
