@import 'assets/styles/mixins';

.links {
	padding: 10px 20px 0;
	font-weight: 400;

	&_item {
		margin-bottom: 16px;
		color: $color-base-grey;
		font-size: 14px;
		line-height: 16px;
		cursor: pointer;
		transition: filter 0.3s ease-in-out;

		&:hover {
			filter: brightness(60%);
		}
	}

	&_copyright {
		padding-bottom: 20px;
		color: $color-middle-gray;
		font-size: 13px;
		line-height: 16px;
		text-transform: uppercase;
	}
}
