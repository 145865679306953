@import 'assets/styles/mixins';

.actual {
	padding: 9px;
	color: $color-typography-primary;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	background: $color-orange-typography;

	&_schedule {
		margin: 0 20px;
	}
}
