@import 'src/assets/styles/mixins';

@keyframes background {
	0% {
		background-position: center 0;
	}

	50% {
		background-position: center 100%;
	}

	100% {
		background-position: center 0;
	}
}

.button {
	display: flex;
	align-items: center;
	padding: 16px 24px;
	color: $color-primary-dark;
	font-size: 14px;
	background-color: $color-white;
	border-radius: 8px;
	box-shadow: 0 4px 8px 0 #2d30400a;
	transition: background-color 0.5s linear, color 0.5s linear;
}

.download {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 167px;
	height: 51.59px;
	margin: 0 auto;
	color: $color-white;
	background-color: $color-green;
	cursor: pointer;

	&__text {
		margin-right: 7px;
	}

	&:hover {
		background-color: $color-green-dark !important;
	}
}

.generating {
	cursor: default;

	p {
		margin: 0;
		padding: 0;
		background: linear-gradient(45deg, $color-green, $color-gray-darkest, $color-green);
		background-position: center 100%;
		background-size: auto 500%;
		background-clip: text;
		animation: background 5s linear infinite;
		-webkit-text-fill-color: transparent;
	}

	&_long {
		max-width: 300px;
		margin-top: 20px;
		color: $color-typography-tertiary;
		font-size: 12px;
		line-height: 14px;
	}
}

.disabled {
	color: $color-typography-tertiary;
	cursor: default;
}

.error {
	color: $color-primary-dark;
	background-color: $color-white;
	box-shadow: 0 4px 8px 0 #2d30400a;
}
