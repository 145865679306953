@import 'assets/styles/global';

.list {
	color: $color-primary-dark;
	font-weight: 400;
	font-size: 11px;
	line-height: 16px;
	background: $color-white;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	box-shadow: 0 4px 8px 0 #00000029;
}

.close,
.confirm {
	flex: 1;
	height: 42px;
	padding: 12px;
	line-height: normal;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
}

.close {
	margin-right: 5px;
	background: transparent;
	border: 1px solid #ffffff14;
}

.confirm {
	margin-left: 5px;
	background: $color-green;
	border: 1px solid $color-green;
}
