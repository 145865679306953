@import 'src/assets/styles/mixins';

.loader {
	position: relative;
	width: 16px;
	height: 16px;
	margin: 0 auto;
	overflow: hidden;
	font-size: 10px;
	background: $color-gray-dark;
	background: linear-gradient(to left, $color-gray-dark 10%, rgb(101 171 248 / 0%) 42%);
	background: linear-gradient(to right, $color-gray-dark 10%, rgb(101 171 248 / 0%) 42%);
	border-radius: 50%;
	transform: translateZ(0);
	animation: load3 1.4s infinite linear;
}

.loader::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 50%;
	background: $color-gray-dark;
	border-radius: 100% 0 0;
	content: '';
}

.loader::after {
	position: absolute;
	inset: 0;
	width: 75%;
	height: 75%;
	margin: auto;
	background: white;
	border-radius: 50%;
	content: '';
}

.confirm::after {
	background: #373c46;
}

.login {
	margin: auto 10px auto 0;
}

.loader.login::after {
	background: $color-green;
}

.loader.login::before {
	background: $color-green-white;
}

@keyframes load3 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
