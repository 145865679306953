@import 'assets/styles/mixins';

.eplusBanner {
	height: 160px;
	overflow: hidden;
	background-color: $color-white;
	border: 1px solid $color-service-grey;
	border-radius: 16px;
	cursor: pointer;

	img {
		max-width: 100%;
		height: 100%;
		transition: filter 0.5s linear;
	}

	&:hover {
		box-shadow: $shadow;
		filter: brightness(95%);
	}
}

@include respond-below(sm) {
	.eplusBanner {
		width: 100%;
		background-color: $color-white;
	}
}
