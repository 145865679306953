@import 'assets/styles/mixins';

.tabs {
	display: flex;
	justify-content: flex-start;
}

.tab {
	display: flex;
	justify-content: space-between;
	padding: 9px 11px 9px 7px;
	border: 1px solid $color-service-grey;
	border-radius: 8px;
	cursor: pointer;
	transition: border 0.3s linear, box-shadow 0.3s linear;

	svg path {
		stroke: #d0d0dd;
	}

	&__text {
		margin-left: 8px;
		color: $color-primary-dark;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
	}

	&__active &__text {
		color: $color-white;
	}

	&_sales {
		margin-right: 8px;
	}

	&:hover {
		border: 1px solid $color-light-green;
		box-shadow: 0 0 0 2px #8dc64040;

		svg path {
			transition: stroke 0.3s linear;
			stroke: $color-green;
		}
	}

	&__active {
		color: $color-white;
		background-color: $color-green;
		border: 1px solid $color-green;
		transition: background-color 0.3s linear;

		svg path {
			stroke: #c6e29f;
		}

		&:hover {
			background-color: $color-green-dark;
			border: 1px solid $color-light-green;
			box-shadow: 0 0 0 2px #8dc64040;
		}
	}
}
