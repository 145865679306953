@import 'assets/styles/mixins';

.list {
	background-color: $color-white;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
	box-shadow: $shadow;

	&_item {
		padding: 4px 8px;
		color: $color-base-grey;
		font-size: 12px;
		cursor: pointer;
		transition: background-color 0.5s ease;

		&:hover {
			color: $color-gray-dark;
			background-color: $color-gray-lightest;
		}
	}
}
