@import 'assets/styles/mixins';

.cookies {
	padding: 20px 20px 200px;

	&_header {
		padding-bottom: 12px;
		color: $color-black;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
	}

	&_content {
		width: 60%;
		padding: 20px 20px 0;
		color: $color-cookies;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		text-align: justify;
	}

	&_links {
		display: flex;
		justify-content: flex-start;
		padding-left: 20px;
	}

	&_link {
		margin-right: 24px;
		transition: all 0.3s ease-in;

		&:hover {
			transform: scale(1.1);

			svg > path {
				filter: brightness(80%);
			}
		}
	}

	a {
		color: $color-green;
		text-decoration: underline;
		cursor: pointer;
		transition: color 0.3s ease-in-out;

		&:hover {
			color: $color-dark-green;
		}
	}

	span {
		font-weight: 700;
	}

	p {
		margin-top: 0;
		margin-bottom: 24px;
	}
}

@include respond-below(md) {
	.cookies {
		padding: 15px 15px 200px;

		&_content {
			width: 100%;
			padding: 0;
		}

		&_header {
			padding-bottom: 16px;
			font-size: 16px;
		}

		&_links {
			padding-left: 0;
		}
	}
}
