@import 'src/assets/styles/mixins';

.down {
	position: absolute;
	display: flex;
	align-items: center;
	height: 20px;
	padding: 0 12px;
	overflow-x: hidden;
	background-color: $color-red-danger-opacity;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;

	.text {
		max-height: 40px;
		-webkit-line-clamp: 1;
	}

	&_one {
		height: 40px;

		.text {
			max-height: 40px;
			-webkit-line-clamp: 2;
		}
	}
}

.hover {
	background-color: $color-red-danger;
}

.text {
	display: block;
	display: -webkit-box;
	width: inherit;
	padding: 2px;
	overflow: hidden;
	color: $color-white;
	font-size: 11px;
	line-height: 16px;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}
