@import 'src/assets/styles/mixins';

@keyframes breathe {
	0% {
		background-color: $color-green;
		transform: scale(1);
	}

	50% {
		background-color: $color-green-dark;
		outline: 5px solid $color-green-white;
		transform: scale(1.3);
	}

	100% {
		background-color: $color-green;
		transform: scale(1);
	}
}

.events {
	position: relative;
	display: inline-flex;
	align-items: center;

	&_count {
		position: absolute;
		top: -5px;
		right: -5px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 19px;
		height: 19px;
		color: $color-white;
		font-weight: 700;
		font-size: 8px;
		background: $color-green-dark;
		border-radius: 50%;
		animation: breathe 3s infinite;
	}
}

.events_active {
	svg > path {
		fill: $color-green;
	}
}
