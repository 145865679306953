@import 'src/assets/styles/mixins';

.slider {
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow-x: hidden;
	background: transparent;

	&_block {
		width: 100%;
		padding: 0 10px;
		transition: all 0.3s ease;

		&__visible {
			width: calc(100% - 115px);
			overflow-x: hidden;
		}
	}

	&_list {
		display: flex;
		align-items: center;
		justify-content: start;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	@include respond-below(sm) {
		max-width: 50%;
	}

	@include respond-above(sm) {
		max-width: 60%;
	}

	@include respond-above(md) {
		max-width: 70%;
	}

	&_button {
		position: relative;
		display: none;
		height: 100%;
		background: $color-white;
		border: none;
		cursor: pointer;
		transition: all 0.5s ease;

		svg > path {
			fill: $color-green;
		}

		&:disabled {
			pointer-events: none;

			svg > path {
				fill: $color-gray-light;
			}
		}

		&_prev {
			box-shadow: $shadow-light-right;
		}

		&_next {
			box-shadow: $shadow-light-left;
		}

		@include respond-below(sm) {
			width: 40px;
		}
	}
}

.visible {
	display: block;
	width: 56px;
}
