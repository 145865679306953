@import 'src/assets/styles/mixins';

.AllUnits {
	background-color: transparent;
}

.units {
	flex-shrink: 0;
	width: 36px;
	min-width: max-content;
	background: $color-service-grey;
	border-radius: 8px;

	svg > path {
		fill: $color-primary-dark;
	}

	&:hover {
		background: #ddd;
	}

	&_active {
		color: $color-white;
		background: $color-green;

		svg > path {
			fill: $color-white;
		}
	}

	&_disabled {
		cursor: default;

		&:hover {
			background: $color-green;
		}
	}

	&_edit {
		margin-left: 5px;
		background: #8dc64033;
	}
}
