@import 'src/assets/styles/mixins';

.scrollBar {
	width: 100%;
	overflow: auto hidden;
}

.message {
	padding: 0 20px;
	color: $color-base-grey;
}
