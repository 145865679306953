@import 'src/assets/styles/mixins';

.inputText {
	width: 100%;
	height: 36px;

	&__input {
		width: 100%;
		height: 100%;
		padding: 7px 10px;
		background: $color-service-grey;
		border: 1px solid $color-service-grey;
		border-radius: 3px;

		&:focus,
		&:active {
			background: $color-white;
		}

		&::placeholder {
			color: $color-service-grey;
		}

		@include respond-below(xs) {
			color: $color-base-grey;
			font-size: 16px;
		}
	}
}
