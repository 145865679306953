@import 'src/assets/styles/mixins';

.card {
	width: 100%;
	padding-bottom: 50px;
}

.item {
	margin-top: 6px;
	margin-right: -16px;
	margin-left: -16px;
	padding: 0 16px;
	background-color: $color-white;

	&:not(:first-child) {
		margin-top: 23px;
	}

	&__hidden {
		display: none;
	}
}

.order {
	background: inherit;

	@extend .item;
}

.property {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	font-size: 12px;
}

.property:not(:first-child) {
	box-shadow: 0 -1px 0 0 #f1f1f7 inset;
}

.header {
	width: 90%;
	color: $color-black-dark;
	font-weight: 700;
	font-size: 12px;

	&__lacky {
		color: $color-green;
	}
}

.checkX {
	margin-top: -5px;
	margin-bottom: -5px;
	padding: 5px 8px;
	color: $color-green-dark;
	font-size: 10px;
	text-align: center;
	text-transform: uppercase;
	background: $color-green-opacity;
	border: 1px solid $color-green-dark;
	border-radius: 3px;

	&__error {
		color: $color-red;
		background: $color-red-opacity;
		border: 1px solid $color-red;

		@extend .checkX;
	}

	&__warning {
		color: $color-orange;
		background: $color-orange-opacity;
		border: 1px solid $color-orange;

		@extend .checkX;
	}

	[class*='value'] {
		width: max-content;
	}
}

.more {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 50px;
	padding: 32px 0 16px;
	color: $color-gray-more;
	font-weight: 700;
	font-size: 12px;
	cursor: pointer;
}
