@import 'assets/styles/mixins';

.homeText {
	width: 35%;
	padding-top: 24px;
	padding-right: 40px;

	&__header {
		padding: unset;
		font-weight: 500;
		font-size: 36px;
		line-height: 42px;
	}

	&__text {
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;

		&_item {
			list-style-type: disc;
		}
	}
}

@include respond-below(lg) {
	.homeText {
		width: 65%;
		padding: 0;

		&__header {
			font-weight: bold;
			font-size: 28px;
			line-height: 32px;
		}
	}
}

@include respond-below(md) {
	.homeText {
		width: 100%;
		margin-bottom: 40px;

		&__text {
			line-height: 24px;
		}
	}
}

@include respond-below(md) {
	.homeText {
		&__text {
			font-size: 14px;
		}
	}
}
