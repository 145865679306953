@import 'assets/styles/mixins';

.reports {
	margin: 0 17px;

	&_current {
		color: $color-green-dark;
		border-bottom: 1px solid $color-green-dark;
	}

	&_active {
		border-bottom: none;
	}

	&_opened {
		[class*='dropdown__active'] {
			border-bottom: none;

			svg > path {
				fill: $color-black;
			}
		}

		[class*='dropdown__active']:hover {
			[class*='text'] {
				color: $color-green-dark;
			}

			svg > path {
				fill: $color-black;
			}
		}

		[class*='text'] {
			color: $color-green-dark;
		}

		[class*='reportsList'] {
			border-top: 1px solid $color-green-dark;
		}

		[class*='listContainer'] {
			top: 18px;
		}

		[class*='link'] {
			font-weight: 400;
		}
	}
}
