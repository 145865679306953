@import 'src/assets/styles/mixins';

.input {
	position: relative;
	display: block;
	align-self: center;
	width: 16px;
	height: 16px;
	margin-left: 8px;
	padding: 8px;
}

.input input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 16px;
	height: 16px;
	margin: 0;
	cursor: pointer;
	opacity: 0;
}

.input_disable input {
	cursor: default;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 16px;
	height: 16px;
	background-color: #fff;
	border: 1px solid $color-text-name;
	border-radius: 3px;
}

.input:hover input ~ .checkmark {
	background-color: $color-gray-light;
}

.input_disable:hover input ~ .checkmark {
	background-color: $color-white;
}

.input input:checked ~ .checkmark {
	background-color: $color-green;
	border: 1px solid $color-green;
}

.input_disable input:checked ~ .checkmark {
	background-color: $color-gray-light;
	border: 1px solid $color-gray-light;
}

.checkmark::after {
	position: absolute;
	display: none;
	content: '';
}

.input .checkmark::after {
	top: 1px;
	left: 4px;
	width: 5px;
	height: 8px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(35deg);
}

.input input:checked ~ .checkmark::after {
	display: block;
}
