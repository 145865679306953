@import 'src/assets/styles/mixins';

.eventItemBlock {
	display: flex;
	margin-bottom: 10px;

	&__checked {
		padding: 20px 0;
	}
}

.eventItem {
	width: 100%;
	background-color: $color-white;
	border-radius: 12px;
	box-shadow: $shadow;
	cursor: pointer;

	&__status {
		display: flex;
		align-self: center;
		margin-top: -2px;
		margin-right: 10px;
	}

	&__title {
		margin-right: 10px;
		color: $color-grayer-text;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
	}

	&__analytics {
		display: flex;
		align-items: center;
	}

	&:hover {
		box-shadow: 0 6px 30px 0 #0003;
	}

	&:hover &__number {
		color: $color-green-dark;
	}

	&__title.active {
		color: $color-green-dark;
	}

	&__text {
		margin-right: 10px;
	}

	&__number {
		margin-right: 10px;
		font-weight: 700;
		font-size: 13px;

		a {
			color: $color-green;
			text-decoration: underline;
		}
	}

	&__date {
		color: $color-text-name;
		font-weight: 500;
		font-size: 13px;
	}

	&__arrow {
		margin-left: 10px;
		cursor: pointer;
	}

	&__current {
		margin-top: 6px;
	}

	&__read {
		color: $color-text-name;
		background: inherit;
		border: 1px solid #e4e4ef;
		box-shadow: unset;

		[class*='title'] {
			color: $color-text-name;
		}

		[class*='number'] {
			font-weight: 500;
		}

		[class*='default'] {
			svg > path {
				fill: $color-text-name;
			}
		}

		@extend .eventItem;
	}
}

.top {
	display: flex;
	justify-content: space-between;
	padding: 20px;
}

.top.visible {
	border-bottom: 1px solid #8dc6404d;
}

.bottom {
	padding: 20px;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}

.error svg > path {
	fill: $color-red;
}

.success svg > path {
	fill: $color-green;
}

.warning svg > path {
	fill: $color-orange;
}

.left,
.right {
	display: flex;
	align-items: center;
}

.left {
	justify-content: start;
}

.right {
	justify-content: end;
}

.visible .eventItem__arrow svg {
	transform: rotate(180deg);
}

.visible .eventItem__arrow svg > path {
	fill: $color-green;
}

@include respond-below(xs) {
	.eventItemBlock {
		&__checked {
			display: flex;
			flex-grow: 1;
			justify-content: right;
			padding: 0;
		}
	}

	.eventItem {
		margin-left: 0;

		&__arrow {
			display: none;
		}

		&__date {
			font-size: 10px;
		}

		&__number,
		&__title,
		&__status {
			font-size: 13px;
			line-height: 15.76px;
		}

		&__title {
			display: -webkit-box;
			margin-top: 16px;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		&__current {
			-webkit-line-clamp: 50;
		}

		[class*='checkmark'] {
			background: $color-gray-lightest;
			border: none;
		}
	}

	.top {
		display: block;
		padding: 16px;
	}

	.right {
		justify-content: start;
		margin-top: 16px;
	}

	[class*='mobile'] {
		flex-grow: 1;
	}
}
