@import 'assets/styles/mixins';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 7000;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: #0000000f;

	&_body {
		width: 420px;
		padding: 16px;
		background: $color-white;
		border-radius: 12px;
		box-shadow: 0 15px 30px 0 #00000014;
	}

	&_title {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
	}

	&_close {
		width: max-content;
		margin: 0 0 0 auto;
		cursor: pointer;

		&:hover {
			svg > path {
				fill: $color-gray-dark;
			}
		}
	}

	&_actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;
	}

	&_action {
		width: 49%;
		padding: 12px;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		border-radius: 6px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
	}

	&_confirm {
		color: $color-white;
		background: $color-base-grey;
		border: 1px solid $color-base-grey;

		&:hover {
			background: $color-green-dark;
		}
	}

	&_cancel {
		border: 1px solid $color-green-opacity;

		&:hover {
			background: $color-gray-lightest;
		}
	}

	&_disabled {
		color: $color-gray-light;
		background-color: $color-text-name;
		border: 1px solid $color-text-name;
		cursor: unset;

		&:hover {
			color: $color-gray-light;
			background-color: $color-text-name;
			border: 1px solid $color-text-name;
			filter: unset;
		}
	}

	.field {
		margin-bottom: 16px;

		&_error {
			margin-bottom: 0;
		}

		&_line {
			display: flex;
			justify-content: space-between;

			[class*='ant-picker-focused'] {
				background: $color-white;
				border: 1px solid $color-green;
				box-shadow: none;
			}

			[class*='ant-picker']:not([class*='ant-picker-input'], [class*='ant-picker-focused']):hover {
				border: 1px solid $color-service-grey;
			}

			&__item {
				width: 49%;
				height: 36px;
				background: $color-service-grey;
				border: 1px solid $color-service-grey;
				border-radius: 3px;

				[class*='ant-picker-input'] input {
					top: 1px;
					margin-left: 20px;
					font-size: 12px;
				}

				&:first-child::before,
				&:last-child::before {
					position: absolute;
					left: 12px;
					display: block;
					color: #c3cad3;
					font-size: 12px;
				}

				&:first-child::before {
					content: attr(title);
				}

				&:last-child::before {
					content: attr(title);
				}
			}
		}

		&_label {
			margin-bottom: 8px;
			color: $color-base-grey;
			font-weight: 500;
			font-size: 12px;
			line-height: 20px;

			&_required::after {
				padding-left: 5px;
				color: $color-green;
				content: '*';
			}
		}
	}
}

.active::after {
	position: absolute;
	top: 46%;
	left: 5px;
	display: block;
	width: 5px;
	height: 5px;
	background: $color-green;
	border-radius: 50%;
	content: '';
}

.error {
	padding-bottom: 0;
	color: $color-red;
	font-size: 10px;
	text-align: right;
	opacity: 0;
	transition: padding-bottom 0.1s ease-in-out, opacity 0.1s ease-in-out;

	&_error {
		padding-bottom: 8px;
		opacity: 1;
	}
}

.message {
	max-height: max-content;
	margin-bottom: 0;
	border-radius: 3px;
	transition: all 0.1s ease-in-out;

	&_hidden {
		max-height: 1px;
		margin-bottom: 1px;
		padding: 1px;
		color: transparent;
		background-color: transparent;
		opacity: 0;
	}

	&_error {
		max-height: max-content;
		margin-bottom: 1px;
		padding: 3px 20px;
		color: $color-white;
		font-size: 12px;
		background-color: $color-red;
		border-radius: 5px;
		opacity: 1;
	}

	&_success {
		max-height: max-content;
		margin-bottom: 1px;
		padding: 3px 20px;
		color: $color-white;
		font-size: 12px;
		background-color: $color-green;
		opacity: 1;
	}
}
