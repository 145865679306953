@import 'src/assets/styles/mixins';

.button {
	display: inline-block;

	&__item {
		width: max-content;
		padding: 14px 20px;
		color: $color-white;
		font-weight: 600;
		font-size: 16px;
		background-color: $color-green;
		border: 1px solid $color-gray-light;
		border-radius: 3px;
		cursor: pointer;

		&:hover {
			background-color: $color-green-dark;
			border-color: $color-green-dark;
		}

		@include respond-below(md) {
			width: 100%;
		}
	}
}
