@import 'src/assets/styles/mixins';

.panel {
	position: relative;
	display: flex;
	margin-top: 20px;

	&__scroll {
		position: absolute;
		bottom: -25px;
		left: 0;
		display: block;
		width: 85px;
		height: 7px;
		background: $color-service-grey;
	}
}

.name {
	margin-left: 11px;
	color: $color-middle-gray;
	font-weight: 600;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-align: center;
	border-left: 1px solid $color-middle-gray;
	transform: rotate(180deg);
	writing-mode: vertical-lr;
}

.axisY {
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	margin: 0;

	&_row {
		display: flex;
		width: 100%;
		height: 56px;
		background-color: $color-grayer;
	}

	&_value {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		padding-right: 10px;
		color: $color-middle-gray;
		font-weight: 600;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 0.01em;
		background-color: $color-grayer;
	}

	&_line {
		align-self: center;
		width: 100%;
		height: 1px;
		background-color: $color-middle-gray;
	}
}

.schedule {
	position: absolute;
	left: 60px;
	display: flex;
	justify-content: flex-start;
	width: calc(100vw - 76px);
	margin: 16px 0;
	overflow-x: auto;

	&::-webkit-scrollbar {
		height: 7px;
		background-color: $color-service-grey;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-base-grey;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: $color-service-grey;
	}
}

.tooltip {
	display: flex;
	width: max-content;
	padding: 2px 6px;
	color: $color-white;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.01em;
	background-color: $color-brand-secondary;
	border-radius: 6px;
	box-shadow: 0 2px 8px 0 #00000014;

	&_code {
		margin-right: 6px;
		color: $color-schedule-tooltip-quantity;
	}

	&_quantity {
		margin-left: 6px;
		color: $color-schedule-tooltip-quantity;
	}
}
